import React from 'react';
import './footer.scss';
const Footer = () => {
  return (
    <footer className="bg-dark py-4 ">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <ul className="list-inline">
              <li className="list-inline-item">
                <a href="/" className="text-white">
                  Home
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <ul className="list-inline">
              <li className="list-inline-item">
                <a href="/contact" className="text-white">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <ul className="list-inline">
              <li className="list-inline-item">
                <a href="/privacy" className="text-white">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <ul className="list-inline">
              <li className="list-inline-item">
                <br className="d-md-none" />
                <a href="/tnc" className="text-white">
                  T&C
                </a>
              </li>
            </ul>
          </div>

          <div className="col-md-12 text-md-center mt-4">
            <p className="text-white">
              &copy; FutureBash 2023. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
