import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Button, Container, Row, Col, Card } from 'react-bootstrap';
import { FaBitcoin, FaEthereum } from 'react-icons/fa'; // Icons for Crypto
import { SiPaytm, SiPhonepe, SiGooglepay, SiTether } from 'react-icons/si'; // Icons for UPI

// CSS in JS for the icon styles
const iconStyles = {
  marginRight: '10px',
  marginLeft: '10px',
  background: 'linear-gradient(to right, #ff9966, #ff5e62)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
};

const cardStyles = {
  border: '1px solid lightgray',
  borderRadius: '5px',
  padding: '20px',
  margin: '10px',
};

const DepositPage = () => {
  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          <Card style={cardStyles} className="mb-4">
            <LinkContainer to="/fiat-deposit">
              <Button variant="primary" size="lg" block>
                Deposit in INR
              </Button>
            </LinkContainer>
            <div className="d-flex justify-content-around mt-3">
              <SiPhonepe size={48} style={iconStyles} />
              <SiGooglepay size={48} style={iconStyles} />
              <SiPaytm size={48} style={iconStyles} />
            </div>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card style={cardStyles} className="mb-4">
            <LinkContainer to="/crypto-deposit">
              <Button variant="secondary" size="lg" block>
                Deposit in Crypto
              </Button>
            </LinkContainer>
            <div className="d-flex justify-content-around mt-3">
              <FaBitcoin size={48} style={iconStyles} />
              <FaEthereum size={48} style={iconStyles} />
              <SiTether size={48} style={iconStyles} />
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DepositPage;
