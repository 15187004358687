export const Token = () => {
  const tokenKey = 'AUTH_TOKEN';

  const saveToken = data => {
    const details = JSON.stringify(data);
    localStorage.setItem(tokenKey, details);
  };

  const getToken = () => {
    return localStorage.getItem(tokenKey);
  };

  const isTokenExist = () => {
    return !!getToken();
  };

  const removeToken = () => {
    localStorage.removeItem(tokenKey);
  };

  const getTokenPayloadData = () => {
    const tokenData = JSON.parse(getToken());
    if (!tokenData) {
      return;
    }
    const token = tokenData.token;
    const tokenValue = JSON.parse(window.atob(token.split('.')[1]));
    const data = {
      ...tokenValue,
      email: tokenData.email,
      name: tokenData.name,
      profile: tokenData.profile,
      is_first_deposit: tokenData.is_first_deposit,
      is_pan: tokenData.is_pan_verified,
      is_phone: tokenData.is_phone_verified,
    };
    return data;
  };

  return {
    saveToken,
    getToken,
    isTokenExist,
    removeToken,
    getTokenPayloadData,
  };
};

export default Token;
