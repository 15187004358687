import './orderlist.scss';

const Orderlist = ({ details, onOrderClick }) => {
  const array = Array.from({ length: 19 }, (_, i) => 0.5 + i * 0.5);

  return (
    <div id="orderlist" className="row">
      <hr />
      <div className="col-12 text-primary fw-bold mb-2">
        <h4 className="app-text-primary">Orderbook</h4>
      </div>
      <div className="col-4 text-center text-primary fw-bold mb-2">Buy YES</div>
      <div className="col-4 text-center text-danger fw-bold">
        <span className="text-secondary">Quantity</span>
      </div>
      <div className="col-4 text-center text-danger fw-bold mb-2">Buy NO</div>
      <div className="col-6">
        {details.yesOrders
          .filter(({ count }) => count > 0)
          .map((item, index) => (
            <div
              key={index}
              className="yes-row row text-secondary"
              onClick={() => onOrderClick('Yes', item.value)}
              style={{ cursor: 'pointer' }}
            >
              <div
                className="progressbar"
                style={{ width: item.percent + '%' }}
              ></div>
              <div className="col-6 text-left fw-bold">{item.value}</div>
              <div className="col-6 text-right">{item.count}</div>
            </div>
          ))}
      </div>
      <div className="col-6">
        {details.noOrders
          .filter(({ count }) => count > 0)
          .map((item, index) => (
            <div
              key={index}
              className="no-row row text-secondary"
              onClick={() => onOrderClick('No', item.value)}
              style={{ cursor: 'pointer' }}
            >
              <div
                className="progressbar"
                style={{ width: item.percent + '%' }}
              ></div>
              <div className="col-6 text-left">{item.count}</div>
              <div className="col-6 text-right fw-bold">{item.value}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Orderlist;
