import apiClient from './apiClient';

export const getReferralDetails = () => {
  return apiClient.get(`refer/getReferralDetails`);
};

export const getReferralEarnings = () => {
  return apiClient.get(`refer/getReferralEarnings`);
};

export const checkReferralCode = data => {
  return apiClient.post(`refer/referralCode`, data);
};
