import { useEffect, React, useState } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Home from '../pages/Home/home';
import TopNav from '../components/top-nav/top-nav';
import HomeTopNav from '../components/top-nav/home-top-nav.js';

import BottomNav from '../components/bottom-nav/bottom-nav';
import Event from '../pages/Event/event';
import EvenDev from '../pages/Event/orderbook';
import Login from '../pages/Authentication/login';
//import Signup from '../pages/Authentication/signup';
import ReferralSignup from '../pages/Authentication/referralSignup';
import { useSelector } from 'react-redux';
import ForgetPassword from '../pages/Authentication/forgetPassword';
import Profile from '../pages/Profile/profile';
import Portfolio from '../pages/Portfolio/portfolio';
import EventPortfolio from '../pages/Portfolio/eventPortfolio.js';
import PortfolioDev from '../pages/Portfolio/portfolio-dev';
import Deposit from '../pages/Deposit/deposit';
import DepositCrypto from '../pages/Deposit/deposit-crypto';
import DepositFiat from '../pages/Deposit/deposit-fiat';
import WithdrawFiat from '../pages/Withdrawal/fiat-withdrawal';
import WithdrawCrypto from '../pages/Withdrawal/crypto-withdrawal';
import Withdrawal from '../pages/Withdrawal/withdrawal';
import ConnectWithWhatsApp from '../pages/Authentication/connectWithWhatsApp';
import MyBets from '../pages/Portfolio/MyBets/myBets';
import Wallet from '../pages/Wallet/wallet';
import Contact from '../pages/Contact/contact';
import Footer from '../components/footer/footer';
import Privacy from '../pages/privacy-tnc/privacy';
import TNC from '../pages/privacy-tnc/tnc';
import EmailVerification from '../components/email-verify';
import ResetPassword from '../pages/Authentication/resetPassword';
import Referral from '../pages/Referral/referral';
import GetApp from '../pages/App/getapp';
import KycPage from '../pages/kyc/kyc';
import BankLink from '../pages/bank/bankAccountLink';
import DeepLink from "../pages/Authentication/deepLink"

function AppRouter(props) {
  const isLoggedIn = useSelector(state => state.token.isTokenExist);
  const location = useLocation();

  useEffect(() => {}, []);

  return (
    <div className="d-flex flex-column h-100 primary-bg">
      {/* top navbar displays in all the screens */}
      {location.pathname === '/home' ? (
        <HomeTopNav setLoggedIn={props.setLoggedIn} />
      ) : (
        <TopNav setLoggedIn={props.setLoggedIn} />
      )}
      {!location.pathname.includes('login') && (
        <div style={{ height: '6rem' }}></div>
      )}
      <div className="app-bg flex-grow-1">
        {/* app routes */}
        <Routes>
          {!isLoggedIn ? (
            <>
              <Route path="/login" element={<Login />} />
              {/* <Route path="/signup/*" element={<Signup />} /> */}
              <Route
                path="/signup/referral/:referralCode"
                element={<ReferralSignup />}
              />
              <Route path="/forget-password" element={<ForgetPassword />} />
              <Route path="/verify-email/*" element={<EmailVerification />} />
              <Route
                path="/reset-password/:token"
                element={<ResetPassword />}
              />
              <Route path="/*" element={<Navigate replace to="/home" />} />
            </>
          ) : (
            <>
              <Route
                path="/connect-whatsapp"
                element={<ConnectWithWhatsApp />}
              />
              <Route path="/profile" element={<Profile />} />
              <Route path="/wallet" element={<Wallet />} />
              <Route path="/crypto-deposit" element={<DepositCrypto />} />
              <Route path="/fiat-deposit" element={<DepositFiat />} />
              <Route path="/deposit" element={<Deposit />} />
              <Route path="/kyc" element={<KycPage />} />
              <Route path="/connect-bank-account" element={<BankLink />} />

              <Route path="/fiat-withdraw" element={<WithdrawFiat />} />
              <Route path="/crypto-withdraw" element={<WithdrawCrypto />} />

              <Route path="/withdraw" element={<Withdrawal />} />
              <Route path="/portfolio/:tab?" element={<Portfolio />} />
              <Route path="/portfolio/:tab/:id" element={<EventPortfolio />} />
              <Route path="/portfolio-dev" element={<PortfolioDev />} />
              <Route path="/referral" element={<Referral />} />

              {/* <Route path="/mybets" element={<MyBets />} /> */}
            </>
          )}
          <Route path="/authcode/:code" element={<DeepLink />} />
          <Route path="/home" element={<Home />} />
          <Route path="/event/:id" element={<Event />} />
          <Route path="/orderbook/:id" element={<EvenDev />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/tnc" element={<TNC />} />
          <Route path="/getapp" element={<GetApp />} />

          <Route path="*" element={<Navigate to="/home" />} />

          {/* redirect route */}
        </Routes>
      </div>
      <Footer />
      {/* bottom navbar only visible in mobile screen in all routes*/}
      <div style={{ height: '5rem' }}></div>
      <BottomNav />
    </div>
  );
}

export default AppRouter;
