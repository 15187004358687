import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
// global css
import './theme/global.scss';

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Loader from './components/common/loader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppRouter from './routers';

function App() {
  return (
    <div className="h-100">
      <Loader />
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<AppRouter />} />
          <Route element={<Navigate replace to="/" />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer autoClose={2000} position="top-right" />
    </div>
  );
}

export default App;
