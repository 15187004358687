import { Link, useNavigate } from 'react-router-dom';
import * as loginServe from '../../api/auth';
import * as UserService from '../../api/userDetails';

import { useState } from 'react';
import Token from '../../utils/tokenHandler';
import { useDispatch } from 'react-redux';
import { setToken } from '../../store/token-slice';
import { showLoader, hideLoader } from '../../store/loader-slice';
import React, { useEffect } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';

const ConnectWhatsapp = () => {
  const [formValues, setFormValues] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const navigate = useNavigate();

  // Add this function in the Login component to handle the WhatsApp login
  const handleWhatsAppLogin = async () => {
    /* const script = document.createElement("script");
    script.src = "https://otpless.com/auth.js";
    script.async = true;
    document.body.appendChild(script); */
    const otplessWidget = document.getElementById('otpless-floating-button');
    if (otplessWidget) {
      otplessWidget.classList.add('show-widget');
    }
  };

  // on values change

  useEffect(() => {
    document.body.classList.add('login-page');

    return () => {
      document.body.classList.remove('login-page');
    };
  }, []);

  useEffect(() => {
    // Set the otpless callback function
    window.otpless = async otplessUser => {
      try {
        console.log(JSON.stringify(otplessUser));
        let result;

        const payload = {
          waNumber: otplessUser.waNumber,
          first_name: otplessUser.waName,
          waToken: otplessUser.token,
          waTimezone: otplessUser.timezone,
          login_method: 'wa',
        };
        result = await UserService.updateWhatsappNumber(payload); // call your API to link WhatsApp

        if (result) {
          navigate('/home');
        }
      } catch (error) {
        console.log('error', error);

        if (error.response) {
          // HTTP response error, status property is set
          if (error.response.status === 401) {
            setErrorMessage('Invalid login. Please try again.');
          } else if (error.response.status === 409) {
            setErrorMessage(
              'This WhatsApp number is associated with another account. Sign out from whatsapp widget below and try again from different number.',
            );
          } else {
            setErrorMessage(
              'An error occurred while logging in. Please try again later.',
            );
          }
        } else {
          // Other types of errors (e.g., network error, JavaScript error, etc.)
          setErrorMessage(
            'An unexpected error occurred. Please try again later.',
          );
        }
      } finally {
        //document.body.classList.remove("login-page");
        //dispatch(hideLoader());
      }
    };
  }, []); // add isLoggedIn dependency

  return (
    <div className="container-fluid h-75 login">
      <div className="row h-100 justify-content-center align-items-center">
        <div className="col-12 col-md-8 col-lg-4 col-xl-3">
          {errorMessage && (
            <div style={{ color: 'red', textAlign: 'center' }}>
              {errorMessage}
            </div>
          )}

          {/* Show the message */}
          <div style={{ textAlign: 'center' }}>
            <p style={{ fontWeight: 'bold' }}>IMPORTANT</p>
            <p>
              We are shifting to WhatsApp based authentication. We will phase
              out email based login soon and your email login would stop
              working.
            </p>
            <p>Please connect your account with WhatsApp to proceed.</p>
          </div>

          {/* Add Connect with WhatsApp button if logged in */}
          <button
            className={`app-btn app-bg-primary w-100 d-flex align-items-center justify-content-center`}
            style={{ fontSize: '16px' }}
            onClick={handleWhatsAppLogin}
          >
            <FaWhatsapp className="me-2" size={20} color="white" /> Connect with
            WhatsApp
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConnectWhatsapp;
