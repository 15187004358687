import './top-nav.scss';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { exitToken } from '../../store/token-slice';
import Token from '../../utils/tokenHandler';
import * as WalletService from '../../api/wallet';
import { updateBalance, walletActions } from '../../store/wallet-slice';
import { convertUsdToInr, convertInrToUsd } from '../../utils/currencyHandler';

function TopNav(params) {
  const isLoggedIn = useSelector(state => state.token.isTokenExist);
  const [showDropdownMenu, setDropdownmenu] = useState(false);
  const [tokenData, setTokenData] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = Token();
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);

  const balance = useSelector(state => state.wallet?.balance);

  const logout = () => {
    token.removeToken();
    dispatch(exitToken());
    setDropdownmenu(false);
  };

  const getPayloadData = async () => {
    const data = await token.getTokenPayloadData();
    setTokenData(data);
  };

  const navagiatePage = page => {
    navigate(page);
    setDropdownmenu(false);
  };

  useEffect(() => {
    getPayloadData();
    if (isLoggedIn) {
      WalletService.getWalletBalance()
        .then(response => {
          const balance =
            Number(response.data.mainBalance) +
            Number(response.data.bonusBalance) +
            Number(response.data.winBalance);
          //setWalletBalance(balance);
          dispatch(walletActions.updateBalance(balance));
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [dispatch, isLoggedIn]);

  useEffect(() => {
    const handleOutsideClick = e => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setDropdownmenu(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [dropdownRef]);

  const onClick = () => {
    setIsActive(!isActive);
  };

  return (
    <div className="w-100 top-0 start-0 mb-4 top sticky-top">
      <div className="container">
        <div className="row justify-content-between align-items-center">
          {/* Offer Text */}

          {/* logo */}
          <div className="col-5 col-md-4 d-flex align-items-center">
            <NavLink to="/" className="logo navbar-brand">
              <i
                className="bi bi-bullseye me-2 text-primary"
                style={{ fontSize: '40px' }}
              ></i>
              <span
                className="text-primary"
                style={{ fontSize: '40px', fontWeight: 'bold' }}
              >
                Future
              </span>
              <span
                className="text-secondary"
                style={{ fontSize: '40px', fontWeight: 'bold' }}
              >
                Bash
              </span>
            </NavLink>
          </div>

          <div className="col-7 col-md-5 col-xl-4 d-flex justify-content-end align-items-center">
            {isLoggedIn ? (
              <>
                <span className="me-2" style={{ fontSize: '0.9rem' }}>
                  ₹{convertUsdToInr(balance)}
                </span>

                <Link
                  to="/deposit"
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 'fit-content',
                    height: '34px',
                    backgroundImage:
                      'linear-gradient(to right, #007bff, #00bfff)',
                    borderRadius: '12%',
                    padding: '5px 5px',
                    textDecoration: 'none',
                    color: '#fff',
                    fontSize: '0.8rem',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <i
                    className="bi bi-plus bi-sm"
                    style={{ marginRight: '5px' }}
                  ></i>
                  Add Funds
                </Link>

                <div className="mx-md-3 position-relative">
                  <div className="text-end">
                    <img
                      onClick={() => setDropdownmenu(!showDropdownMenu)}
                      className="avathat_img"
                      src="/assets/avathar.png"
                      alt="Profile"
                    />
                    {/* dropdown */}
                    <div
                      ref={dropdownRef}
                      className={`dropdown_menu ${
                        showDropdownMenu
                          ? 'active_dropdown_menu'
                          : 'dropdown_menu_hide'
                      }`}
                    >
                      {/* Your existing dropdown code */}
                      <div className="d-flex">
                        <img
                          className="avathat_img"
                          src="/assets/avathar.png"
                          alt="Profile"
                        />
                        <div className="ms-1 mt-2">
                          <small className="mb-0">{tokenData?.email}</small>
                        </div>
                      </div>
                      <hr />
                      {/* profile details */}
                      <div className="dropdown_menu_items">
                        <div
                          className="row"
                          onClick={() => navagiatePage('/portfolio/polls')}
                        >
                          <div className="col-2 mb-2">
                            <i className="bi bi-bullseye"></i>
                          </div>
                          <h6 className="col-10 mb-2">My Predictions</h6>
                        </div>
                        <div
                          className="row"
                          onClick={() => navagiatePage('/wallet')}
                        >
                          <div className="col-2 mb-2">
                            <i className="bi bi-wallet2"></i>
                          </div>
                          <h6 className="col-10 mb-2">Wallet</h6>
                        </div>
                        {
                          <div
                            className="row"
                            onClick={() => navagiatePage('/referral')}
                          >
                            <div className="col-2 mb-2">
                              <i className="bi bi-people"></i>
                            </div>
                            <h6 className="col-10 mb-2">Referrals</h6>
                          </div>
                        }
                        <div
                          className="row"
                          onClick={() => navagiatePage('/profile')}
                        >
                          <div className="col-2 mb-2">
                            <i className="bi bi-person"></i>
                          </div>
                          <h6 className="col-10 mb-2">Profile</h6>
                        </div>
                        <div
                          className="row"
                          onClick={() => navagiatePage('/contact')}
                        >
                          <div className="col-2 mb-2">
                            <i className="bi bi-envelope"></i>
                          </div>
                          <h6 className="col-10 mb-2">Contact Us</h6>
                        </div>

                        <hr />

                        <div className="row" onClick={logout}>
                          <div className="col-2 mb-2">
                            <i className="bi bi-box-arrow-left"></i>{' '}
                          </div>
                          <h6 className="col-10 mb-2">Log Out</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="text-end">
                {/* <Link
                  to="/signup"
                  className="app-btn app-bg-primary text-white w-100"
                >
                  Register
                </Link> */}
                <Link
                  to="/login"
                  className="app-btn app-bg-primary text-white w-100"
                  style={{ marginLeft: '10px' }}
                >
                  Log In
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopNav;
