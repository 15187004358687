import { useState, useEffect } from 'react';
import Token from '../../utils/tokenHandler';
import * as eventServe from '../../api/event';
import * as orderBookService from '../../api/orderbook';

import * as dateFormate from '../../utils/dateformate';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { showLoader, hideLoader } from '../../store/loader-slice';
import { useDispatch } from 'react-redux';
import { ArrowsAngleContract, ArrowsAngleExpand } from 'react-bootstrap-icons';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import './portfolio.scss';

const BetsTable = ({ bets, status, handleCancelOrder }) => {
  useDocumentTitle('My Predictions');

  const getStatusClassByStatus = orderStatus => {
    switch (orderStatus) {
      case 'open':
        return 'text-dark';
      case 'partially_matched':
        return 'text-warning';
      case 'matched':
        return 'text-success';
      default:
        return '';
    }
  };
  const [showModal, setShowModal] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);

  const openModal = orderId => {
    setSelectedOrderId(orderId);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedOrderId(null);
    setShowModal(false);
  };

  const confirmCancelOrder = () => {
    handleCancelOrder(selectedOrderId);
    closeModal();
  };

  return bets.length > 0 ? (
    <>
      <table className="table table-hover table-responsive-md">
        <thead>
          <tr>
            <th className="fw-bold">Event</th>
            <th className="fw-bold">You said</th>
            <th className="fw-bold">Your bid</th>
            <th className="fw-bold">Slippage</th>
            <th className="fw-bold">Fulfilled</th>
            <th className="fw-bold">Amount</th>

            <th className="fw-bold">Status</th>
            <th className="fw-bold">Action</th>
          </tr>
        </thead>
        <tbody>
          {bets.map((x, i) => {
            const statusClass = getStatusClassByStatus(x?.status);

            return (
              <tr key={i} className={i % 2 === 0 ? 'bg-light' : ''}>
                <td>
                  <p>{x?.eventId?.event_name}</p>
                </td>
                <td>
                  <p>{x?.shareType}</p>
                </td>
                <td className="">
                  <p>
                    {x?.shareAmount} @ ${x?.sharePrice.toFixed(2)}
                  </p>
                </td>
                <td className="">
                  <p>{x?.maxSlippage}%</p>
                </td>
                <td className="">
                  <p>
                    {x?.executedShares} @ ${(x?.executedSharePrice).toFixed(3)}
                  </p>
                </td>
                <td className="">
                  <p>
                    ${(x?.executedShares * x?.executedSharePrice).toFixed(3)}
                  </p>
                </td>

                <td className={statusClass}>
                  <p>{x?.status}</p>
                </td>
                <td>
                  {(x?.status === 'open' ||
                    x?.status === 'partially_matched') && (
                    <button
                      className="btn btn-danger btn-sm"
                      style={{ marginTop: '0px' }}
                      onClick={() => openModal(x?._id)}
                    >
                      Cancel
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to cancel this order?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          <Button variant="danger" onClick={confirmCancelOrder}>
            Cancel Order
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <div className="text-center mt-4">
      <h4>No predictions found.</h4>
    </div>
  );
};

const Portfolio = () => {
  const [myBets, setMyBets] = useState([]);
  const [betsAmount, setBetsAmount] = useState(0);
  const [status, setStatus] = useState('open');
  const dispatch = useDispatch();
  const [compactView, setCompactView] = useState(true);

  const handleStatusChange = value => {
    setStatus(value);
    getUserOrders(value);
    //getMyBets(value === "open" ? "open" : "matched");
  };
  const toggleCompactView = () => {
    setCompactView(!compactView);
  };
  // get mybet details
  const getMyBets = async (status = 'open') => {
    try {
      dispatch(showLoader());

      const data = await eventServe.getMyBets(status);
      let bet_amount = 0;
      await data?.data.map(ele => {
        bet_amount = +ele.bet_amount + bet_amount;
      });
      setBetsAmount(bet_amount);
      setMyBets(data?.data.reverse());
      dispatch(hideLoader());
    } catch (error) {
      console.log(error);
      dispatch(hideLoader());
    }
  };

  const getUserOrders = async status => {
    try {
      dispatch(showLoader());

      const data = await orderBookService.getMyOrders(status);

      console.log('data', data);
      // Handle the data as needed, for example, set it to your state
      // ...
      setMyBets(data?.data.data.reverse());

      dispatch(hideLoader());
    } catch (error) {
      console.log(error);
      dispatch(hideLoader());
    }
  };

  const handleCancelOrder = async orderId => {
    try {
      // Call the API to cancel the order
      const response = await orderBookService.cancelOrder(orderId);

      if (response.status) {
        // Refresh the bets table data after a successful cancellation
        // You can call your existing function to fetch bets data here
        getUserOrders();
      } else {
        // Show error message if the cancellation was not successful
        console.error('Order cancellation failed');
      }
    } catch (error) {
      console.error('Error cancelling the order:', error);
    }
  };

  useEffect(() => {
    //getMyBets();
    getUserOrders('open');
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={`container portfolio`}>
      {/* top invesement card */}

      {/* trade status */}
      <div className="row mt-3 p-2 justify-content-around justify-content-md-start align-items-center">
        <div
          onClick={() => handleStatusChange('open')}
          className={`col-auto p-2 mx-md-1 rounded text-center cursor-pointer ${
            status === 'open' ? 'filter-items-active' : 'filter-items'
          }`}
        >
          Open Orders
        </div>
        <div
          onClick={() => handleStatusChange('matched')}
          className={`col-auto p-2 mx-md-1 rounded text-center cursor-pointer ${
            status === 'matched' ? 'filter-items-active' : 'filter-items'
          }`}
        >
          Matched Orders
        </div>

        {/* Toggle compact view icon */}
        <div className="col text-center text-md-end">
          <span
            onClick={toggleCompactView}
            className="cursor-pointer"
            style={{ fontSize: '1.5rem' }}
            title={
              compactView ? 'Switch to Card View' : 'Switch to Compact View'
            }
          >
            {compactView ? (
              <ArrowsAngleExpand size={24} />
            ) : (
              <ArrowsAngleContract size={24} />
            )}
          </span>
        </div>
      </div>

      {/* event cards */}
      <div className="row mt-3">
        <div className="col-12">
          {compactView ? (
            <BetsTable
              bets={myBets}
              status={status}
              handleCancelOrder={handleCancelOrder}
            />
          ) : (
            <div className="row">
              {/* card */}
              {myBets.map((x, i) => {
                const multiplierText =
                  status === 'Active'
                    ? 'Current multiplier'
                    : 'Final multiplier';
                const probableWinText =
                  status === 'Active' ? 'Probable Win' : 'You won';

                return (
                  <div
                    key={i}
                    className="bet_card col-12 col-lg-6 py-2 col-xl-4 my-1"
                  >
                    <div className="bet_card_shadow ">
                      {/* image */}
                      <div className="row align-items-center">
                        <div className="col-3">
                          <img
                            src={`${
                              x?.event_id?.image?.s3Url || '/assets/event.png'
                            }`}
                            alt="icon"
                            className="trade-icon w-100"
                          />
                        </div>
                        {/* yes / no buttons */}
                        <div className="col-9">
                          <div className="border yes_no_btn d-flex px-3 py-2">
                            <p className="mb-0 p-2 px-2 ">
                              Prediction{' '}
                              <span
                                className={`fw-bold ${
                                  x?.bet_result === 'Yes'
                                    ? 'app-text-primary'
                                    : 'text-danger'
                                }`}
                              >
                                {x?.bet_result}
                              </span>
                            </p>
                            <span className="border-start border-dark"></span>
                            <p className="mb-0 p-2 px-2">
                              Invested{' '}
                              <span className="fw-bold app-text-primary">
                                ${x?.bet_amount}
                              </span>
                            </p>
                            <span className="border-start border-dark"></span>
                            <p className="mb-0 p-2 px-2">
                              Result{' '}
                              <span
                                className={`fw-bold ${
                                  x?.bet_result === 'Yes'
                                    ? 'app-text-primary'
                                    : 'text-danger'
                                }`}
                              >
                                {x?.event_id?.result
                                  ? x?.event_id?.result
                                  : '___'}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <p className="p-2 fw-bold">{x?.event_id?.event_name}</p>

                      <div className="row align-items-center justify-content-around justify-content-md-start mt-4">
                        <div className="overlay bottom-overlay">
                          <div className="overlay-content-bottom d-flex">
                            <div className="progress-bar-container">
                              <span
                                className="progress-label"
                                style={{ width: '20px' }}
                              >
                                {Math.round(
                                  (x?.event_id.yesBetsTotal * 100) /
                                    (x?.event_id.yesBetsTotal +
                                      x?.event_id.noBetsTotal),
                                )}
                                %
                              </span>
                              <div
                                className="progress-bar-fill-yes"
                                style={{
                                  width: `${
                                    (x?.event_id.yesBetsTotal * 100) /
                                    (x?.event_id.yesBetsTotal +
                                      x?.event_id.noBetsTotal)
                                  }%`,
                                  flexGrow: '1',
                                }}
                              ></div>
                            </div>

                            <div className="progress-bar-container">
                              <span
                                className="progress-label"
                                style={{ width: '20px' }}
                              >
                                {Math.round(
                                  (x?.event_id.noBetsTotal * 100) /
                                    (x?.event_id.yesBetsTotal +
                                      x?.event_id.noBetsTotal),
                                )}
                                %
                              </span>

                              <div
                                className="progress-bar-fill-no"
                                style={{
                                  width: `${
                                    (x?.event_id.noBetsTotal * 100) /
                                    (x?.event_id.yesBetsTotal +
                                      x?.event_id.noBetsTotal)
                                  }%`,
                                  flexGrow: '1',
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr />
                      {/* event date details */}
                      <div className="row justify-content-around">
                        <div className="col-6 col-md-4">
                          <p className="text-small mb-0 app-text-primary text-center">
                            Started
                          </p>
                          <p className="text-center cfs-6">
                            {dateFormate.formatDate(
                              x?.event_id?.event_start_date,
                              'small',
                            )}
                          </p>
                        </div>
                        <div className="col-6 col-md-4">
                          <p className="text-small mb-0 app-text-primary text-center">
                            End Date
                          </p>
                          <p className="text-center cfs-6">
                            {dateFormate.formatDate(
                              x?.event_id?.event_end_date,
                              'small',
                            )}
                          </p>
                        </div>
                        <div className="col-12 col-md-4">
                          <p className="text-small mb-0 app-text-primary text-center">
                            Settlement
                          </p>
                          <p className="text-center cfs-6">
                            {dateFormate.formatDate(
                              x?.event_id?.event_settlement_date,
                              'small',
                            )}
                          </p>
                        </div>
                      </div>
                      <hr />
                      {/* event invesment details */}
                      <div className="row justify-content-around">
                        <div className="col-4 text-center">
                          <p className="cfs-6 text-nowrap">Investment</p>
                          <p className="fw-bold">${x?.bet_amount}</p>
                        </div>
                        <div className="col-4 text-center">
                          <p className="cfs-6 text-nowrap">{multiplierText}</p>
                          <p className="fw-bold">
                            {x?.bet_result === 'Yes'
                              ? x.event_id.multiplier_yes
                              : x.event_id.multiplier_no}{' '}
                            x {x.bet_result}
                          </p>
                        </div>
                        <div className="col-12 col-md-4 text-center">
                          <p className="cfs-6 text-nowrap">{probableWinText}</p>
                          <p className="fw-bold">
                            <i
                              className="bi bi-info-circle-fill app-text-secondary fs-6 align-middle me-2"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="The multiplier and your final win will change depending on the total amount of bets placed."
                            ></i>
                            $
                            {x.status === 'open'
                              ? x.bet_result === 'Yes'
                                ? (
                                    x.bet_amount * x.event_id.multiplier_yes
                                  ).toFixed(2)
                                : (
                                    x.bet_amount * x.event_id.multiplier_no
                                  ).toFixed(2)
                              : x.win_amount.toFixed(2)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
