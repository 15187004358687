import React from 'react';

const Privacy = () => {
  return (
    <div className="container mt-12">
      <h1>Privacy Policy</h1>
      <p>
        This privacy policy discloses the privacy practices for FutureBash and
        our website; https://www.futurebash.com. This privacy policy applies
        solely to information collected by this website, except where stated
        otherwise. It will notify you of the following:
      </p>
      <ul>
        <li>What information we collect;</li>
        <li>With whom it is shared;</li>
        <li>How it can be corrected;</li>
        <li>How it is secured;</li>
        <li>How policy changes will be communicated; and</li>
        <li>How to address concerns over misuse of personal data.</li>
      </ul>

      <h3>Information Collection, Use, and Sharing</h3>
      <p>
        We are the sole owners of the information collected on this site. We
        only have access to/collect information that you voluntarily give us via
        email or other direct contact from you. We will not sell or rent this
        information to anyone.
      </p>
      <p>
        We will use your information to respond to you, regarding the reason you
        contacted us. We will not share your information with any third party
        outside of our organization, other than as necessary to fulfill your
        request, e.g., to ship an order.
      </p>

      <h3>Your Access to and Control Over Information</h3>
      <p>
        You may opt out of any future contacts from us at any time. You can do
        the following at any time by contacting us via the email address given
        on our website:
      </p>
      <ul>
        <li>See what data we have about you, if any;</li>
        <li>Change/correct any data we have about you;</li>
        <li>Have us delete any data we have about you;</li>
        <li>Express any concern you have about our use of your data.</li>
      </ul>

      <h3>Security</h3>
      <p>
        We take precautions to protect your information. When you submit
        sensitive information via the website, your information is protected
        both online and offline.
      </p>
      <p>
        Wherever we collect sensitive information (such as credit card data),
        that information is encrypted and transmitted to us in a secure way. You
        can verify this by looking for a lock icon in the address bar and
        looking for "https" at the beginning of the address of the Web page.
      </p>
      <p>
        While we use encryption to protect sensitive information transmitted
        online, we also protect your information offline. Only employees who
        need the information to perform a specific job (for example, billing or
        customer service) are granted access to personally identifiable
        information. The computers/servers on which we store personally
        identifiable information are kept in a secure environment.
      </p>

      <h3>Notification of Changes</h3>
      <p>Notification of Changes:</p>

      <p>
        FutureBash reserves the right to change this Privacy Policy from time to
        time, and at its sole discretion. FutureBash encourages visitors to
        frequently check this page for any changes to its Privacy Policy. Your
        continued use of this site after any change in this Privacy Policy will
        constitute your acceptance of such change.
      </p>
      <p>Contact Information:</p>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        via email at contact@futurebash.com.
      </p>
    </div>
  );
};

export default Privacy;
