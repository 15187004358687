import apiClient from './apiClient';

export const login = data => {
  return apiClient.post(`/users/login`, data);
};

export const signup = data => {
  return apiClient.post(`/users/signup`, data);
};
export const createWhatsAppUser = data => {
  return apiClient.post(`/users/createWhatsAppUser`, data);
};

export const forgetPassword = data => {
  return apiClient.post(`/users/forgot`, data);
};

export const getBalance = () => {
  return apiClient.get(`/users/balance`);
};

export const updateUserDetails = data => {
  return apiClient.put(`/users/${data._id}`, data);
};

export const reSetPassword = data => {
  return apiClient.post(`/users/reset`, data);
};

export const sendEmail = data => {
  return apiClient.post(`/users/sendEmail`, data);
};

export const verifyEmail = token => {
  return apiClient.get(`/users/verifyEmail/${token}`);
};

export const uploadImage = data => {
  return apiClient.post(`/upload`, data);
};
