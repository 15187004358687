import React, {useEffect} from 'react';
import { useParams } from 'react-router-dom';
import {FaWhatsapp} from "react-icons/fa";
const DeepLink = () => {
    let { code } = useParams();
    useEffect(() => {
        window.location.href = `futurebash.com://login/${code}`;
    }, [code]);

    return (
        <div className="container-fluid h-75 login">
            <div className="row h-100 justify-content-center align-items-center">
                <div className="col-12 col-md-8 col-lg-4 col-xl-3">


                    {/* Show the message */}
{/*                    <div style={{ textAlign: 'center' }}>
                        <p style={{ fontWeight: 'bold' }}>IMPORTANT</p>
                        <p>
                            We are shifting to WhatsApp based authentication. We will phase
                            out email based login soon and your email login would stop
                            working.
                        </p>
                        <p>Please connect your account with WhatsApp to proceed.</p>
                    </div>*/}

                    <a href={`futurebash.com://login/${code}`}>
                        <button
                        className={`app-btn app-bg-primary w-100 d-flex align-items-center justify-content-center`}
                        style={{ fontSize: '16px' }}
                    >
                        <FaWhatsapp className="me-2" size={20} color="white" /> Open Futurebash Application
                    </button>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default DeepLink;
