import './bottom-nav.scss';

import { NavLink } from 'react-router-dom';

function BottomNav() {
  return (
    <div className="d-md-none w-100 position-fixed bottom-0 start-0 py-2 bottom-nav">
      <div className="container">
        {/* bottom nav bar */}
        <div className="d-flex overflow-auto justify-content-around h-100">
          <NavLink
            to="home"
            className={({ isActive }) =>
              isActive ? 'nav_select bottom_nav_style' : 'bottom_nav_style'
            }
          >
            <i className="bi bi-calendar3 fs-3"></i>
            <span className="ms-2 d-none">Market</span>
          </NavLink>
          <NavLink
            to="/portfolio"
            className={({ isActive }) =>
              isActive ? 'nav_select bottom_nav_style' : 'bottom_nav_style'
            }
          >
            <i className="bi bi-bullseye fs-3"></i>
            <span className="ms-2 d-none">Portfolio</span>
          </NavLink>
          <NavLink
            to="wallet"
            className={({ isActive }) =>
              isActive ? 'nav_select bottom_nav_style' : 'bottom_nav_style'
            }
          >
            <i className="bi bi-wallet2 fs-3"></i>
            <span className="ms-2 d-none">Wallet</span>
          </NavLink>
          <NavLink
            to="referral"
            className={({ isActive }) =>
              isActive ? 'nav_select bottom_nav_style' : 'bottom_nav_style'
            }
          >
            <i className="bi bi-people fs-3"></i>
            <span className="ms-2 d-none">Referral</span>
          </NavLink>
          {/* <NavLink
                        to="community"
                        className={({ isActive }) =>
                            isActive
                                ? 'nav_select bottom_nav_style'
                                : 'bottom_nav_style'
                        }
                    >
                        <i className="bi bi-lightbulb fs-3"></i>
                        <span className="ms-2 d-none">Community</span>
                    </NavLink> */}

          {/* <NavLink
                        to="/profile"
                        className={({ isActive }) =>
                            isActive
                                ? 'nav_select bottom_nav_style'
                                : 'bottom_nav_style'
                        }
                    >
                        <i className="bi bi-person-circle fs-3"></i>
                        <span className="ms-2 d-none">Account</span>
                    </NavLink> */}
        </div>
      </div>
    </div>
  );
}

export default BottomNav;
