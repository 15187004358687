import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import * as userDetailsServe from '../../api/userDetails';
import * as loginServe from '../../api/auth';
import { useNavigate } from 'react-router-dom';

const AddBankAccount = () => {
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [statusMessage, setStatusMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loginMethod, setLoginMethod] = useState(null);
  const [bankSuccess, setBankSuccess] = useState(false);

  const [bankDetails, setBankDetails] = useState({
    accountNumber: '',
    accountName: '',
    ifsc: '',
    branchAddress: '',
  });

  const [phone, setPhone] = useState({
    countryCode: '+91',
    number: '',
  });
  const countries = ['+91'];

  const [phoneError, setPhoneError] = useState(null);

  const [accountNumberError, setAccountNumberError] = useState(null);
  const [accountNameError, setAccountNameError] = useState(null);
  const [ifscError, setIfscError] = useState(null);
  const [formValid, setFormValid] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const checkKYCStatus = async () => {
      const user = await loginServe.getBalance();
      console.log('user', user.data);
      if (!user.data.is_kyc_verified) {
        navigate('/kyc'); // replace "/kyc" with your actual KYC page route
      } else {
        console.log('user.data.login_method', user.data.login_method);
        setLoginMethod(user.data.login_method);
      }
    };
    checkKYCStatus();
  }, []); // empty dependency array means this useEffect will only run once, when the component is first mounted

  useEffect(() => {
    if (!/^\d*$/.test(bankDetails.accountNumber)) {
      setAccountNumberError('Account number must only contain numbers.');
    } else {
      setAccountNumberError(null);
    }

    if (!/^[A-Za-z\s]*$/.test(bankDetails.accountName)) {
      setAccountNameError('Account name must only contain letters.');
    } else {
      setAccountNameError(null);
    }

    if (!/^[A-Za-z0-9]*$/.test(bankDetails.ifsc)) {
      setIfscError('IFSC code must be alphanumeric.');
    } else {
      setIfscError(null);
    }

    if (
      !accountNumberError &&
      !accountNameError &&
      !ifscError &&
      bankDetails.branchAddress !== ''
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [bankDetails, accountNumberError, accountNameError, ifscError]);

  const handleSubmit = async () => {
    setIsLoading(true);
    setShowModal(false);
    let retryCount = 0; // Define retryCount here instead of using useRef

    try {
      const body = {
        bankDetails,
      };
      const response = await userDetailsServe.createUserBank(body);

      if (response.data.status === 'SUCCESS') {
        setStatusMessage(
          'Your bank detail is verified successfully. You are being redirected.',
        );
        setErrorMessage(null);
        setIsLoading(false);
      } else if (
        response.data.status === 'FAILED' ||
        response.data.status === 'UNKNOWN'
      ) {
        setIsLoading(false);
        setErrorMessage(
          'Your bank details verification failed. Please try again.',
        );
      } else if (response.data.status === 'PENDING') {
        const checkStatus = async () => {
          if (retryCount >= 30) {
            alert(
              'Timeout: Bank details verification taking longer than expected. Please try again. ',
            );

            //setErrorMessage("Timeout: Bank details verification taking longer than expected. Please try again. ");
            setIsLoading(false);

            return; // exit the function if retryCount is 30 or more
          }
          const verificationResponse =
            await userDetailsServe.checkVerificationStatusAPI(
              response.data.referenceNumber,
            ); // replace with your API
          if (verificationResponse.data.status === 'SUCCESS') {
            setErrorMessage(null);
            setStatusMessage(
              'Your bank detail is verified successfully. You are being redirected.',
            );
            setIsLoading(false);
          } else if (verificationResponse.data.status === 'PENDING') {
            retryCount++;
            setTimeout(checkStatus, 10000); // delay the next call by 10 seconds if status is still PENDING
          } else if (verificationResponse.data.status === 'FAILED') {
            setIsLoading(false);
            //setErrorMessage("Your bank details verification failed");
            alert('Your bank details verification failed. Please try again.');
          }
        };
        checkStatus();
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setErrorMessage(
        'There was an error processing your request. Please try again.',
      );
    }
  };

  const handleModalSubmit = async () => {
    setShowModal(false);
    await handleSubmit();
  };

  const handleFormSubmit = event => {
    event.preventDefault();
    if (
      !bankDetails.accountNumber ||
      !bankDetails.accountName ||
      !bankDetails.ifsc ||
      !bankDetails.branchAddress
    ) {
      alert('Please fill in all required fields');
    } else {
      setShowModal(true);
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          {bankSuccess ? (
            <>
              <h2 className="text-center mb-3">
                Bank Account linked successfully!
              </h2>
              <button
                className="btn btn-primary w-100"
                onClick={() => (window.location.href = '/fiat-withdraw')}
              >
                Proceed to Withdrawal
              </button>
            </>
          ) : (
            <>
              <h2 className="text-center my-4">Add Bank Account</h2>

              <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    Please confirm the following details:
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>
                    <strong>Account Number: </strong>{' '}
                    {bankDetails.accountNumber}
                  </p>
                  <p>
                    <strong>Account holder Name: </strong>{' '}
                    {bankDetails.accountName}
                  </p>
                  <p>
                    <strong>IFSC: </strong> {bankDetails.ifsc}
                  </p>
                  <p>
                    <strong>Branch Address: </strong>{' '}
                    {bankDetails.branchAddress}
                  </p>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={handleModalSubmit}>
                    Confirm
                  </Button>
                </Modal.Footer>
              </Modal>

              <form onSubmit={handleFormSubmit}>
                <div className="form-group">
                  <label>Account Number</label>
                  <input
                    type="text"
                    className="form-control"
                    value={bankDetails.accountNumber}
                    onChange={e => {
                      const inputValue = e.target.value;
                      // Check if the input value is a number
                      if (/^[0-9]*$/.test(inputValue) || inputValue === '') {
                        setBankDetails({
                          ...bankDetails,
                          accountNumber: inputValue,
                        });
                      }
                    }}
                    maxLength="25"
                    required
                  />
                  {accountNumberError && (
                    <div style={{ color: 'red' }}>{accountNumberError}</div>
                  )}
                </div>
                <div className="form-group">
                  <label>Account Holder Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={bankDetails.accountName}
                    onChange={e => {
                      const inputValue = e.target.value;
                      if (
                        /^[A-Za-z\s]*$/i.test(inputValue) ||
                        inputValue === ''
                      ) {
                        setBankDetails({
                          ...bankDetails,
                          accountName: inputValue,
                        });
                      }
                    }}
                    required
                  />
                  {accountNameError && (
                    <div style={{ color: 'red' }}>{accountNameError}</div>
                  )}
                </div>
                <div className="form-group">
                  <label>IFSC</label>
                  <input
                    type="text"
                    className="form-control"
                    value={bankDetails.ifsc}
                    onChange={e => {
                      const inputValue = e.target.value;
                      if (
                        /^[A-Za-z0-9]*$/i.test(inputValue) ||
                        inputValue === ''
                      ) {
                        setBankDetails({
                          ...bankDetails,
                          ifsc: inputValue,
                        });
                      }
                    }}
                    maxLength="11"
                    required
                  />

                  {ifscError && <div style={{ color: 'red' }}>{ifscError}</div>}
                </div>
                <div className="form-group">
                  <label>Branch Address</label>
                  <input
                    type="text"
                    className="form-control"
                    value={bankDetails.branchAddress}
                    onChange={e => {
                      const inputValue = e.target.value;
                      if (
                        /^[A-Za-z0-9\s]*$/i.test(inputValue) ||
                        inputValue === ''
                      ) {
                        setBankDetails({
                          ...bankDetails,
                          branchAddress: inputValue,
                        });
                      }
                    }}
                    required
                  />
                </div>
                {loginMethod !== 'wa' && (
                  <div className="form-group row  mt-3">
                    <label
                      htmlFor="phoneNumber"
                      className="col-sm-3 col-form-label"
                    >
                      Phone:
                    </label>
                    <div className="col-sm-9">
                      <div className="row">
                        <div className="col-md-4 col-4 mb-2 mb-md-0">
                          <select
                            className="form-control"
                            id="phoneCountryCode"
                            value={phone.countryCode}
                            onChange={e => {
                              const newPhoneState = {
                                ...phone,
                                countryCode: e.target.value,
                              };
                              setPhone(newPhoneState);
                            }}
                            required
                          >
                            {countries.map((country, index) => (
                              <option key={index} value={country}>
                                {country}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-md-8 col-8">
                          <input
                            type="text"
                            className="form-control"
                            id="phoneNumber"
                            value={phone.number}
                            onChange={e => {
                              const inputValue = e.target.value;
                              setPhone({ ...phone, number: inputValue });

                              if (inputValue.length === 10) {
                                if (!/^[0-9]*$/i.test(inputValue)) {
                                  setPhoneError(
                                    'Please enter a valid phone number.',
                                  );
                                } else {
                                  setPhoneError(null);
                                }
                              } else {
                                setPhoneError(
                                  'Phone number must be 10 digits.',
                                );
                              }
                            }}
                            maxLength={10}
                            required
                          />
                          {phoneError && (
                            <div style={{ color: 'red' }}>{phoneError}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <button
                  type="submit"
                  className="btn btn-primary w-100"
                  disabled={!formValid || isLoading}
                >
                  {isLoading ? (
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    'Submit Bank Details'
                  )}
                </button>
              </form>

              {isLoading && <div>Verifying bank details...</div>}

              {errorMessage && (
                <div className="alert alert-danger mt-4" role="alert">
                  {errorMessage}
                </div>
              )}
              {statusMessage && (
                <div className="alert alert-success mt-4" role="alert">
                  {statusMessage}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddBankAccount;
