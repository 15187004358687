import { checkReferralCode } from '../api/refer';

const handleReferralCodeIfExists = () => {
  const referralCode = localStorage.getItem('referralCode');

  if (referralCode) {
    checkReferralCode({ referralCode })
      .then(response => {
        localStorage.removeItem('referralCode');
      })
      .catch(error => {});
  }
};

export default handleReferralCodeIfExists;
