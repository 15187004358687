import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

function ReferralSignup() {
  const { referralCode } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem('referralCode', referralCode);
    navigate('/home');
  }, [referralCode, navigate]);

  return <div>Please wait...</div>;
}

export default ReferralSignup;
