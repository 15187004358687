import { useState } from 'react';
import { Link } from 'react-router-dom';
import * as TransactionService from '../../api/transaction';
import { BiClipboard } from 'react-icons/bi';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { updateBalance } from '../../store/wallet-slice';
import { useDispatch } from 'react-redux';
import * as WithdrawalService from '../../api/wallet';
import QrCodeGenerator from '../../components/common/qrcode';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { isMobile } from 'react-device-detect';
import { convertInrToUsd } from '../../utils/currencyHandler';
import { Modal, Button } from 'react-bootstrap';

const Deposit = () => {
  useDocumentTitle('Deposit');

  const [selectedCurrency, setSelectedCurrency] = useState('busdbsc');
  const [usdAmount, setUsdAmount] = useState(0);
  const [inrAmount, setInrAmount] = useState(0);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [transactionData, setTransactionData] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('waiting');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [utr, setUtr] = useState('');
  const [selectedPaymentApp, setSelectedPaymentApp] = useState('');
  const [apiCallCount, setApiCallCount] = useState(0);
  const [isClicked, setIsClicked] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(
    'Fetching Payment Options',
  );
  const [show, setShow] = useState(false);

  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = async event => {
    event.preventDefault();
    setIsLoading(true);
    // Submit form data to backend API and handle response
    try {
      const body = {
        price_amount: usdAmount,
        price_currency: 'USD',
        pay_currency: '',
        payment_gateway_id: 2,
        priceAmountLocal: inrAmount,
        priceCurrencyLocal: 'INR',
      };
      const response = await TransactionService.createTransaction(body);
      const data = response.data;
      setTransactionData(data);
      setIsSubmitted(true);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleUTRSubmit = async event => {
    event.preventDefault();
    setIsButtonClicked(true);
    setLoadingMessage('Please wait while we fetch the payment status ....');
    setIsLoading(true);
    try {
      const response = await TransactionService.updateTransaction(
        transactionData?._id,
        {
          paymentApp: selectedPaymentApp,
          utr: utr,
        },
      );
      const data = response.data;

      if (response.status == 200) {
        setTransactionData(data.transaction);
        setIsClicked(true);
        // Check payment status every 10 seconds
        const interval = setInterval(async () => {
          if (apiCallCount < 30) {
            const statusResponse = await TransactionService.getTxById(
              transactionData?._id,
            );
            const statusData = statusResponse.data;

            if (
              statusData.paymentStatus === 'orderReceived' ||
              statusData.paymentStatus === 'transferred'
            ) {
              setPaymentStatus('completed');

              clearInterval(interval);
              setIsLoading(false);
              const wallet = await WithdrawalService.getWalletBalance();
              const wallet_balance =
                wallet.data.mainBalance +
                wallet.data.bonusBalance +
                wallet.data.winBalance;
              dispatch(updateBalance(wallet_balance));
            }
            setApiCallCount(prevCount => prevCount + 1);
          } else {
            clearInterval(interval);
            setIsLoading(false);
          }
        }, 10000);
      } else {
        setIsLoading(false);
        alert(data.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  /*   const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 10000); // Clear copied text after 1 second
  }; */
  const handleCheckPaymentStatus = async () => {
    try {
      // Check payment status every 10 seconds
      const interval = setInterval(async () => {
        if (apiCallCount < 30) {
          const statusResponse = await TransactionService.getTxById(
            transactionData?._id,
          );
          const statusData = statusResponse.data;

          if (
            statusData.paymentStatus === 'orderReceived' ||
            statusData.paymentStatus === 'transferred'
          ) {
            setPaymentStatus('completed');

            clearInterval(interval);
            setIsLoading(false);
            const wallet = await WithdrawalService.getWalletBalance();
            const wallet_balance =
              wallet.data.mainBalance +
              wallet.data.bonusBalance +
              wallet.data.winBalance;
            dispatch(updateBalance(wallet_balance));
          }
          setApiCallCount(prevCount => prevCount + 1);
        } else {
          clearInterval(interval);
          setIsLoading(false);
        }
      }, 10000);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 my-3">
          <div className="alert alert-info text-center">
            You will receive 20% bonus on your first deposit.
          </div>
        </div>
      </div>

      <div className="row justify-content-center my-4">
        <div className={`col-12 col-md-6 ${isSubmitted ? 'd-none' : ''}`}>
          <h2 className="text-center my-4">Enter Amount in INR</h2>
          <form onSubmit={handleSubmit}>
            <div className="input-group mb-3">
              <span className="input-group-text">₹</span>
              <input
                type="number"
                className="form-control"
                value={inrAmount}
                onChange={e => {
                  setInrAmount(e.target.value);
                  const convertedUsd = parseFloat(
                    convertInrToUsd(e.target.value).toFixed(4),
                  );
                  setUsdAmount(convertedUsd);
                }}
                onClick={e => (e.target.value = '')}
                onKeyPress={event => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                min="100"
                max="50000"
                pattern="[5-9]|[1-4][0-9]|[5][0-9][0-9]"
                required
              />
            </div>
            {/*             <p className="text-muted">Equivalent USD: {usdAmount} USD</p>
             */}{' '}
            <button type="submit" className="btn btn-primary w-100">
              Deposit Now
            </button>
          </form>
        </div>
      </div>

      {!transactionData && isLoading && (
        <div className="text-center my-3">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <div>{loadingMessage}</div>
        </div>
      )}

      {isSubmitted && !isClicked && paymentStatus !== 'completed' && (
        <div className="row justify-content-center my-4">
          <div className="col-12">
            <div className="card">
              <div className="card-header">Deposit Steps</div>
              <div className="card-body">
                {!isMobile && (
                  <>
                    <p style={{ color: '#f8b739', fontWeight: '600' }}>
                      Step 1: Pay
                    </p>
                    <p className="text-center">
                      Scan QR from an UPI app to pay{' '}
                      <b>₹{transactionData?.priceAmountLocal}</b>
                    </p>
                    <div className="d-flex justify-content-center">
                      <QrCodeGenerator address={transactionData?.payAddress} />
                    </div>
                  </>
                )}
                <p style={{ color: '#f8b739', fontWeight: '600' }}>
                  <p style={{ color: '#f8b739', fontWeight: '600' }}>
                    {isMobile ? 'Select UPI App' : 'Step 2: Select UPI App'}
                  </p>
                </p>
                <div className="d-flex flex-wrap justify-content-center">
                  {transactionData?.deepLinks
                    .slice(0, 4)
                    .map((provider, index) =>
                      isMobile ? (
                        <div key={index} className="m-2">
                          <a
                            href={provider.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => setIsClicked(true)}
                          >
                            <img
                              src={provider.imageUrl}
                              alt={provider.name}
                              width="50"
                              height="50"
                            />
                            <p>{provider.name}</p>
                          </a>
                        </div>
                      ) : (
                        <div
                          key={index}
                          className="m-2"
                          style={{
                            border:
                              selectedPaymentApp === provider.name
                                ? '3px solid #f8b739'
                                : 'none',
                            borderRadius: '5px',
                            padding: '10px',
                          }}
                        >
                          <label>
                            <input
                              type="radio"
                              name="paymentApp"
                              value={provider.name}
                              onChange={e =>
                                setSelectedPaymentApp(e.target.value)
                              }
                              style={{ marginRight: '10px' }}
                            />

                            <img
                              src={provider.imageUrl}
                              alt={provider.name}
                              width="50"
                              height="50"
                            />
                            <p>{provider.name}</p>
                          </label>
                        </div>
                      ),
                    )}
                </div>

                {!isMobile && (
                  <>
                    <p style={{ color: '#f8b739', fontWeight: '600' }}>
                      Step 3: Enter UTR
                    </p>
                    <div className="mt-3">
                      <label htmlFor="utr" className="form-label">
                        Enter UTR:
                      </label>
                      <a
                        style={{
                          marginLeft: '10px',
                          cursor: 'pointer',
                          color: '#f8b739',
                          fontSize: '14px',
                        }}
                        onClick={handleShow}
                      >
                        How to find UTR?
                      </a>
                      <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>How to find the UTR number?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <p>Example: 209905101992, 210203211993.</p>
                          <p>
                            <b>Phonepe</b>
                          </p>
                          <p>
                            Once you have made a transaction via PhonePe, click
                            on <i>View Details</i>.
                          </p>
                          <p>
                            or After a successful transaction on PhonePe, click
                            on <i>Done.</i>
                          </p>
                          <p>
                            Go to history and select the particular transaction.
                            UTR number is the required UTR.
                          </p>
                          <p>
                            <b>Google Pay</b>
                          </p>
                          <p>UPI Transaction ID is the correct UTR.</p>
                          <p>
                            <b>Paytm UPI</b>
                          </p>
                          <p>
                            Once you have made a transaction via Paytm UPI,
                            click on <i>View Details</i>
                          </p>
                          <p>UPI Reference No. is the correct UTR.</p>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      <input
                        type="text"
                        className="form-control"
                        id="utr"
                        value={utr}
                        disabled={!selectedPaymentApp} // disable the field until a UPI app is selected
                        onChange={e => {
                          const re = /^[0-9\b]+$/; // Allow numbers and backspace
                          // if value is not blank, then test the regex
                          if (
                            e.target.value === '' ||
                            (re.test(e.target.value) &&
                              e.target.value.length <= 12)
                          ) {
                            setUtr(e.target.value);
                          }
                        }}
                      />
                      <div className="form-text">
                        Please enter your 12-digit UTR number.
                      </div>
                    </div>
                    <button
                      type="button"
                      className={`btn mt-3 ${
                        isButtonClicked ? 'btn-secondary' : 'btn-primary'
                      }`}
                      onClick={handleUTRSubmit}
                      disabled={
                        !selectedPaymentApp ||
                        !utr ||
                        utr.length < 12 ||
                        isButtonClicked
                      }
                    >
                      Submit
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* {isClicked && paymentStatus !== "completed" && (
        <div className="row justify-content-center my-4">
          <div className="col-12 col-md-6">
            <button
              type="button"
              className="btn btn-outline-primary w-100"
              onClick={() => {
                setIsLoading(true);
                handleCheckPaymentStatus();
              }}
            >
              {isLoading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Checking payment status...
                </>
              ) : (
                <>
                  {paymentStatus === "waiting"
                    ? "I just sent  ₹" + transactionData?.priceAmountLocal
                    : "Click here to check status"}
                </>
              )}
            </button>

            <div className="mt-2 text-center">
              <p>
                If you face any issues with deposits, please{" "}
                <Link to="/contact">contact us</Link>.
              </p>
            </div>
          </div>
        </div>
      )} */}

      {paymentStatus === 'completed' && (
        <div className="row justify-content-center my-4">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex flex-column align-items-center">
                  <div
                    style={{
                      width: '70px',
                      height: '70px',
                      backgroundColor: 'green',
                      borderRadius: '50%',
                      position: 'relative',
                      animation: 'scale-up-down 2s infinite',
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        width: '60%',
                        height: '60%',
                        fill: 'white',
                        position: 'absolute',
                        top: '20%',
                        left: '15%',
                      }}
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M15.354 4.354a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L8 10.793l6.646-6.647a.5.5 0 0 1 .708 0z"
                      />
                    </svg>
                  </div>

                  <style jsx>{`
                    @keyframes scale-up-down {
                      0% {
                        transform: scale(1);
                      }
                      50% {
                        transform: scale(1.1);
                      }
                      100% {
                        transform: scale(1);
                      }
                    }
                  `}</style>

                  <h2 className="mt-4">Payment Successful</h2>
                  <p>Your payment has been successfully processed.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <Link to="/wallet" className="btn btn-primary w-100">
              Go to Wallet
            </Link>
            {/* <div className="mt-2 text-center">
              <p>
                If you face any issues with deposits, please{" "}
                <Link to="/contact">contact us</Link>.
              </p>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Deposit;
