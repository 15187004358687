import { useEffect, useState } from 'react';
import TradeCard from '../../components/trade-card/trade-card';
import Banner from './Banner/banner';
import Filters from './Filters/filters';
import * as eventServe from '../../api/event';
import { useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../../store/loader-slice';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import TopBanner from './TopBanner';
import moment from 'moment';
import { usePagination } from '../../hooks/usePagination';
import { Pagination } from '../../components/pagination/pagination';

const DEFAULT_PARAMS = {
  page: 1,
  perPage: 10,
};

const Home = params => {
  useDocumentTitle('Predict Event Outcomes');

  const dispatch = useDispatch();

  const [eventsList, setEventsList] = useState([]);
  const [eventsListForcarousal, seteventsListForcarousal] = useState([]);
  const { setMeta, meta, query } = usePagination();

  const formatDateToLocal = date => {
    return moment(date).format('hh:mmA'); // 10:30AM
  };

  const getEventsList = async ({ id = '', page, perPage }) => {
    try {
      dispatch(showLoader());

      const data = await eventServe.getEvents({ id, page, perPage });

      const events = data?.data.result.sort(
        (a, b) => new Date(a.event_end_date) - new Date(b.event_end_date),
      );

      const formattedEvents = events.map(event => {
        if (
          !event.event_name ||
          !event.event_end_date ||
          !event.event_settlement_date
        ) {
          console.error('Event object missing expected properties:', event);
          return event; // return as-is, or handle differently
        }

        const formattedEventName = event.event_name
          .replace('{endDate}', formatDateToLocal(event.event_end_date))
          .replace(
            '{settlementDate}',
            formatDateToLocal(event.event_settlement_date),
          );

        return { ...event, event_name: formattedEventName };
      });

      setEventsList(formattedEvents);
      setMeta(data?.data.pagination);

      if (id === '') {
        const footballCategoryId = '64246eb63801c821b7f65b40';
        const footballEvents = data?.data.filter(
          event => event.category === footballCategoryId,
        );
        const sortedFootballEvents = footballEvents.sort(
          (a, b) => new Date(a.event_end_date) - new Date(b.event_end_date),
        );
        seteventsListForcarousal(events.slice(0, 5));
      }
    } catch (error) {
      console.error('Caught error:', error?.error || error);
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    getEventsList({
      id: '',
      page: query?.page ? query?.page : DEFAULT_PARAMS.page,
      perPage: query?.perPage ? query?.perPage : DEFAULT_PARAMS.perPage,
    });
  }, []);

  const handleSelectedCategory = id => {
    getEventsList({
      id,
      page: query?.page ? query?.page : DEFAULT_PARAMS.page,
      perPage: query?.perPage ? query?.perPage : DEFAULT_PARAMS.perPage,
    });
  };

  const handlePagination = (page, perPage) => {
    getEventsList({
      id: query?.category,
      page,
      perPage,
    });
  };

  return (
    <>
      <TopBanner />
      <div className="container">
        <div className="row">
          <div className="col-12">
            {/* <Banner eventsList={eventsListForcarousal} /> */}
            <Filters
              handleSelectedCategory={id => handleSelectedCategory(id)}
            />
            <div className="row">
              {eventsList.length === 0 ? (
                <h2 className="app-text-primary text-center">Loading Events</h2>
              ) : null}
              {eventsList.map((x, i) => {
                return (
                  <div className="col-12 col-sm-6 col-md-4 mb-4" key={i}>
                    <TradeCard details={x} />
                  </div>
                );
              })}
              {Object.keys(meta).length > 0 && (
                <div className="pagination">
                  <Pagination meta={meta} handlePagination={handlePagination} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
