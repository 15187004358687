import { useState, useEffect } from 'react';
import * as WithdrawalService from '../../api/wallet';
import * as TransactionService from '../../api/transaction';
import * as BetsService from '../../api/event';
import { Link } from 'react-router-dom';
import { updateBalance, subtractFromBalance } from '../../store/wallet-slice';

import { useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const Withdrawal = () => {
  useDocumentTitle('Withdrawal');

  const [balance, setBalance] = useState(0);
  const [mainBalance, setMainBalance] = useState(0);
  const [bonusBalance, setBonusBalance] = useState(0);
  const [betAmount, setBetAmount] = useState(0);

  const [address, setAddress] = useState('');
  const [amount, setAmount] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    const fetchWalletBalance = async () => {
      try {
        const response = await WithdrawalService.getWalletBalance();

        const bet_response = await BetsService.getBetAmountForAnUser();

        const total_bet_amount = bet_response.data.data.totalBetAmount;
        setBetAmount(total_bet_amount);
        setMainBalance(response.data.mainBalance);
        if (total_bet_amount < 100) {
          setBalance(Number(response.data.mainBalance));
        } else {
          setBalance(
            Number(response.data.mainBalance) +
              Number(response.data.bonusBalance),
          );
        }
        setBonusBalance(Number(response.data.bonusBalance));
      } catch (error) {
        console.error(error);
      }
    };
    fetchWalletBalance();
  }, []);

  const handleSubmit = () => {
    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);
        const payment_gateway_id = 1;

        const body = {
          address,
          amount,
          payment_gateway_id,
        };
        if (amount < 10) {
          alert('Minimum withdrawable amount is 10 USDT');
          setIsLoading(false);
          return;
        }
        const response = await TransactionService.placeWithdrawalRequest(body);
        const data = response.data;
        setIsSubmitted(true);
        setBalance(balance - amount);
        if (betAmount >= 100) {
          if (amount >= bonusBalance) {
            setBonusBalance(0);
            setMainBalance(mainBalance - (amount - bonusBalance));
          } else {
            setBonusBalance(bonusBalance - amount);
          }
        } else {
          setMainBalance(mainBalance - amount);
        }
        dispatch(subtractFromBalance(amount));

        resolve(data);
      } catch (error) {
        console.error(error);
        reject(error);
      } finally {
        setIsLoading(false);
      }
    });
  };

  const handleConfirm = async () => {
    try {
      await handleSubmit();
      setShowModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <h2 className="text-center my-4">Withdraw USDT</h2>

          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Please confirm the following details:</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <strong>USDT - TRC20 Wallet Address:</strong>{' '}
                <span className="text-primary">{formData?.address}</span>
              </p>
              <p>
                <strong>Amount to Withdraw:</strong>{' '}
                <span className="text-primary">{formData?.amount} USDT</span>
              </p>
              <p className="text-muted">Withdrawal Fee: 1 USDT</p>
              <p>
                You will receive{' '}
                <span className="text-primary">
                  {formData?.amount - 1} USDT
                </span>{' '}
                after the withdrawal fee is deducted.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={async () => {
                  try {
                    await handleSubmit();
                    setShowModal(false);
                  } catch (error) {
                    console.error(error);
                  }
                }}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>

          <form
            onSubmit={event => {
              event.preventDefault();
              if (amount > balance) {
                alert('Withdrawal amount cannot exceed available balance');
              } else if (!address || !amount) {
                alert('Please fill in all required fields');
              } else {
                setFormData({
                  address,
                  amount,
                });
                setShowModal(true);
              }
            }}
          >
            <div className="form-group">
              <label htmlFor="address">USDT - TRC20 Wallet Address:</label>
              <input
                type="text"
                className="form-control"
                id="address"
                value={address}
                onChange={e => setAddress(e.target.value)}
                required
              />
              <small className="form-text text-muted text-info">
                Please make sure that it is a USDT address on Tron (TRC20)
                network
              </small>
            </div>

            <div className="form-group mb-4 py-4">
              <label htmlFor="amount">Amount to Withdraw:</label>
              <div className="input-group">
                <input
                  type="number"
                  className="form-control"
                  id="amount"
                  value={amount}
                  onChange={e => setAmount(e.target.value)}
                  onKeyPress={event => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  min="10"
                  step="1"
                  max={balance}
                  required
                />
                <span className="input-group-text">USDT</span>
              </div>
              <small className="form-text text-muted">
                Withdrawable balance: {balance} USDT | Main balance:{' '}
                {mainBalance} USDT| Bonus balance: {bonusBalance} USDT{' '}
                {betAmount >= 100
                  ? 'Your bonus amount is withdrawable now.'
                  : '(Bonus can be withdrawn once you have placed a total of 100 USD in predictions across the events.)'}
              </small>
            </div>

            {isSubmitted ? (
              <Link to="/wallet" className="btn btn-primary w-100">
                Go to Wallet
              </Link>
            ) : (
              <button type="submit" className="btn btn-primary w-100">
                {isLoading ? (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  'Place Withdraw request'
                )}
              </button>
            )}
          </form>

          {isSubmitted && (
            <div className="alert alert-success mt-4" role="alert">
              Your withdrawal request has been submitted successfully.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Withdrawal;
