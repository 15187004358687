import { configureStore } from '@reduxjs/toolkit';
import loaderSlice from './loader-slice';
import tokenSlice from './token-slice';
import walletSlice from './wallet-slice';

export const store = configureStore({
  reducer: {
    token: tokenSlice,
    loader: loaderSlice,
    wallet: walletSlice,
  },
});
