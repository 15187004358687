import React, { useEffect, useState } from 'react';
import * as orderbookServe from '../../api/orderbook';
import * as events from '../../api/event';
import OrdersTable from './ordersTable'; // Change path_to_OrdersTable to the actual path of OrdersTable.js
import { useParams, NavLink } from 'react-router-dom';
import { Pagination } from '../../components/pagination/pagination';
import { usePagination } from '../../hooks/usePagination';

const DEFAULT_PARAMS = {
  page: 1,
  perPage: 10,
};

const EventPortfolio = () => {
  const [title, setTitle] = useState('');
  const [orders, setOrders] = useState(null);
  const { id } = useParams();
  const { setMeta, meta, query } = usePagination();

  const fetchOrdersForEvent = async ({ page, perPage }) => {
    try {
      const response = await orderbookServe.getOrdersByEventForUser(
        id,
        page,
        perPage,
      );
      if (response && response.data) {
        setOrders(response.data.result);
        setTitle(response.data.event_name);
        setMeta(response.data.pagination);
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  useEffect(() => {
    fetchOrdersForEvent({
      page: query?.page ? query?.page : DEFAULT_PARAMS.page,
      perPage: query?.perPage ? query?.perPage : DEFAULT_PARAMS.perPage,
    });
  }, [id]);

  const handleStatusChange = newStatus => {
    // Logic to filter or fetch orders based on the new status (if necessary)
  };

  const handlePagination = (page, perPage) =>
    fetchOrdersForEvent({ page, perPage });

  return (
    <div className="container mb-4">
      <NavLink to="/portfolio/events" className="nav-link">
        <i class="bi bi-arrow-left arrow-link"></i>
        <span>Back to My Predictions </span>
      </NavLink>
      <h3 className="mb-4">Orders for {title}</h3>
      {orders ? (
        <>
          <OrdersTable
            orders={orders}
            onStatusChange={handleStatusChange}
            refreshOrders={fetchOrdersForEvent}
          />
          {Object.keys(meta).length > 0 && (
            <div className="pagination">
              <Pagination meta={meta} handlePagination={handlePagination} />
            </div>
          )}
        </>
      ) : (
        <div className="loading">Loading...</div>
      )}
    </div>
  );
};

export default EventPortfolio;
