import { useEffect, useState } from 'react';
import './filters.scss';
import * as categoryServe from '../../../api/category';

const Filters = ({ handleSelectedCategory }) => {
  const filters = [
    {
      text: 'News',
      icon: '',
      // subFilters: ['152', 'jdncmdk', '43y7', 'kdvkfmk', 'sb']
    },
    {
      text: 'Market',
      icon: '',
      // subFilters: ['Abd', 'jdncmdk', '74', 'kdvkfmk', 'fmvkfk']
    },
    {
      text: 'Data',
      icon: '',
      // subFilters: ['474', 'jdncmdk', 'jsdnx', 'kdvkfmk', 'fmvkfk']
    },
    {
      text: 'Text',
      icon: '',
      // subFilters: ['0909', 'jdncmdk', 'dncjfn', '7547', 'fmvkfk', '9088']
    },
    {
      text: 'My world',
      icon: '',
      // subFilters: ['643643', 'jdncmdk', 'dncjfn', 'kdvkfmk', 'fmvkfk']
    },
  ];

  const [category, setCategory] = useState([]);

  const [selectFilter, setSelectFilter] = useState('All');

  const getCategorys = async () => {
    try {
      const result = await categoryServe.getCategoryes();
      setCategory(result?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const setSelectFilterValue = id => {
    if (id == -1) {
      setSelectFilter('All');
    } else {
      const filter = category[id].name;
      setSelectFilter(filter);
      handleSelectedCategory(category[id]._id);
    }
  };

  useEffect(() => {
    getCategorys();
  }, []);

  useEffect(() => {
    if (category.length > 0) {
      setSelectFilterValue(-1);
    }
  }, [category]);

  return (
    <div className="filters container-fluid">
      <div className="row py-3">
        <div className="col-12">
          <div className="d-flex overflow-auto">
            <div
              className={`cursor-pointer d-flex align-items-center py-2 px-3 mx-1 ${
                selectFilter === 'All' ? 'filter-items-active' : 'filter-items'
              }`}
              onClick={() => {
                setSelectFilter('All');
                handleSelectedCategory('');
              }}
            >
              <span className="ms-1 text-small">All</span>
            </div>
            {category.map((x, i) => {
              return (
                <div
                  key={i}
                  className={`cursor-pointer d-flex align-items-center py-2 px-3 mx-1 ${
                    selectFilter === x?.name
                      ? 'filter-items-active'
                      : 'filter-items'
                  }`}
                  onClick={() => {
                    setSelectFilterValue(i);
                  }}
                >
                  <span className="ms-1 text-small">{x.name}</span>
                </div>
              );
            })}
          </div>
          {/* sub filters */}
          <div className="my-3">
            {filters.map((x, i) => {
              return (
                <div key={i}>
                  {selectFilter === x.text ? (
                    <div className="d-flex overflow-auto">
                      {x?.subFilters?.map((y, i) => {
                        return (
                          <div
                            key={i}
                            className={`py-2 px-3 mx-1 filter-items`}
                          >
                            <span className="ms-1 text-small">{y}</span>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
