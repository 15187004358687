const usdToInrConversionRate = 80; // Conversion rate from USD to INR

/**
 * Converts the given amount from USD to INR.
 *
 * @param {number} usdAmount - The amount in USD.
 * @return {number} - The equivalent amount in INR.
 */

export const convertUsdToInr = usdAmount => {
  const converted = usdAmount * usdToInrConversionRate;
  return parseFloat(converted.toFixed(2));
};

export const convertInrToUsd = inrAmount => {
  const usdAmount = inrAmount / usdToInrConversionRate;
  return parseFloat(usdAmount.toFixed(4));
};
