import './event.scss';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import * as eventServe from '../../api/event';
import * as loginServe from '../../api/auth';
import { useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../../store/loader-slice';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as dateFormate from '../../utils/dateformate';
import * as walletService from '../../api/wallet';
import { Button, Form } from 'react-bootstrap';
import { updateBalance, subtractFromBalance } from '../../store/wallet-slice';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import Orderlist from './components/orderlist';
import { convertUsdToInr, convertInrToUsd } from '../../utils/currencyHandler';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useLocation } from 'react-router-dom';

const Orderbook = props => {
  useDocumentTitle('Event Details');

  const divRef = useRef(null);

  const location = useLocation();

  const isLoggedIn = useSelector(state => state.token.isTokenExist);
  const [eventDetails, setEventDetails] = useState({});
  const [outcomeSelect, setOutcomeSelect] = useState(null);
  const { id } = useParams();
  const [betAmount, setBetAmount] = useState({ bet_amount: '' });
  const [userBalance, setUserBalance] = useState(0);
  const [isFirstBet, setisFirstBet] = useState(false);
  const [numShares, setNumShares] = useState(1);
  const [maxNumShares, setMaxNumShares] = useState(500);

  const [betDetails, setBetDetails] = useState({
    yesPercentage: 0,
    noPercentage: 0,
  });

  const [sharePrice, setSharePrice] = useState(5);
  const [orderData, setOrderData] = useState({ yesOrders: [], noOrders: [] });
  const [yesSharePrice, setYesSharePrice] = useState('5');
  const [noSharePrice, setNoSharePrice] = useState('5');
  const [userOrders, setUserOrders] = useState([]);
  const [userOrder, setUserOrder] = useState({});
  const [isSellTabActive, setIsSellTabActive] = useState(false);
  const [isActiveYesButton, setIsActiveYesButton] = useState(true);
  const [isActiveNoButton, setIsActiveNoButton] = useState(true);

  const [activeTab, setActiveTab] = useState(
    location.hash === '#sell' ? 'sell' : 'buy',
  );

  const incrementShares = () => {
    updateNumShares(numShares + 1);
  };

  const decrementShares = () => {
    if (numShares > 1) {
      updateNumShares(numShares - 1);
    }
  };

  const handleSharePriceChange = value => {
    setSharePrice(value);
  };

  const dispatch = useDispatch();
  const selectOutcomeHandle = value => {
    setOutcomeSelect(value);
  };

  const getEventDeailsById = async () => {
    try {
      dispatch(showLoader());
      const data = await eventServe.getEventById(id);

      setEventDetails(data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const getBetDetailsByEventId = async () => {
    try {
      dispatch(showLoader());

      const orderData = await eventServe.getOrderStatForEvent(id);
      setOrderData(orderData.data.data);

      const data = await eventServe.getBetsForAnEvent(id);
      if (data.status) {
        const yesBetsTotal = data.data.data.yesBetsTotal;

        const noBetsTotal = data.data.data.noBetsTotal;
        const total = yesBetsTotal + noBetsTotal;
        const yesPercentage =
          total === 0 ? 50 : Math.round((yesBetsTotal / total) * 100);
        const noPercentage =
          total === 0 ? 50 : Math.round((noBetsTotal / total) * 100);

        const multiplier_yes = data.data.data.multiplier_yes;
        const multiplier_no = data.data.data.multiplier_no;

        setBetDetails({
          yesPercentage,
          noPercentage,
          multiplier_yes,
          multiplier_no,
          yesBetsTotal,
          noBetsTotal,
        });
      } else {
        setBetDetails({
          yesPercentage: 50,
          noPercentage: 50,
          multiplier_yes: 1,
          multiplier_no: 1,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleAmountChange = event => {
    setBetAmount({
      ...betAmount,
      [event.target.name]: event.target.value,
    });
  };

  const handleOrder = e => {
    createOrder(e);
  };

  // const sellOrder = async e => {
  //   try {
  //     e.preventDefault();
  //     if (eventDetails?.status !== 'open') {
  //       toast.info('Prediction period for this event is closed.');
  //       return;
  //     }

  //     dispatch(showLoader());

  //     // Create the order data object
  //     const data = {
  //       outcome: outcomeSelect,
  //       numShares,
  //       sharePrice: sharePrice,
  //     };

  //     // Call a new function to sell the order in the order book
  //     const orderSold = await eventServe.sellOrder(id, data);
  //     if (orderSold) {
  //       //setisFirstBet(false);
  //       console.log('sold order executed');
  //       //getOrderBookDetailsByEventId()
  //       getBetDetailsByEventId();
  //     }
  //     toast.success('Your order has been sold successfully');
  //   } catch (error) {
  //     console.log(error);
  //     toast.error(
  //       'Some error occurred. Please wait a while before trying again.',
  //     );
  //   } finally {
  //     dispatch(hideLoader());
  //     setNumShares(1);
  //     setSharePrice(outcomeSelect === 'Yes' ? yesSharePrice : noSharePrice);
  //   }
  // };

  const createOrder = async e => {
    try {
      e.preventDefault();
      if (eventDetails?.status !== 'open') {
        toast.info('Prediction period for this event is closed.');
        return;
      }

      dispatch(showLoader());

      const wallet = await walletService.getWalletBalance();
      //const userBalance = wallet.data.mainBalance + wallet.data.bonusBalance;
      const mainBalance = convertUsdToInr(wallet.data.mainBalance);
      const bonusBalance = convertUsdToInr(wallet.data.bonusBalance);
      const winBalance = convertUsdToInr(wallet.data.winBalance);

      console.log(sharePrice);
      if (Number(userBalance) < Number(numShares * sharePrice)) {
        toast.info(`You do not have sufficient funds`);
        return;
      }

      // Create the order data object
      const data = {
        outcome: outcomeSelect,
        numShares,
        sharePrice: sharePrice,
      };

      // Call a new function to create the order in the order book
      const orderCreated = await eventServe.createOrder(id, data);
      if (orderCreated) {
        let availableBalance = 0;

        availableBalance =
          mainBalance + winBalance + bonusBalance - numShares * sharePrice;

        setUserBalance(availableBalance);
        // getOrderBookDetailsByEventId();
        dispatch(subtractFromBalance(convertInrToUsd(numShares * sharePrice)));
        //setisFirstBet(false);
        getBetDetailsByEventId();
      }
      toast.success('Your order has been placed successfully');
    } catch (error) {
      console.log(error);
      toast.error(
        'Some error occurred. Please wait a while before trying again.',
      );
    } finally {
      dispatch(hideLoader());
      setNumShares(1);
      setSharePrice(outcomeSelect === 'Yes' ? yesSharePrice : noSharePrice);
    }
  };

  const getOrderBookDetailsByEventId = async () => {
    try {
      dispatch(showLoader());
      const data = await eventServe.getOrderBookDetailsForAnEvent(id);
      console.log('data::::', data);
      if (data.status) {
        const { bestYesSharePrice, bestNoSharePrice, userOrders } =
          data.data.data;

        console.log('userOrders', userOrders);
        setUserOrders(userOrders);
        setYesSharePrice(bestYesSharePrice);
        setNoSharePrice(bestNoSharePrice);
      } else {
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const getUserBalance = async () => {
    try {
      const user_wallet = await walletService.getWalletBalance();

      //const balance =user_wallet.data.mainBalance + user_wallet.data.bonusBalance;
      //console.log(balance);
      const betBalanceINR = convertUsdToInr(user_wallet.data.betBalance);

      setUserBalance(betBalanceINR);
    } catch (error) {
      console.log(error);
    }
  };
  const getUserDetails = async () => {
    try {
      const user = await loginServe.getBalance();
      setisFirstBet(user.data.is_first_bet);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNumSharesChange = e => {
    const newValue = e.target.value;
    updateNumShares(newValue);
  };

  const updateNumShares = newValue => {
    if (newValue >= 1 && newValue <= maxNumShares) {
      setNumShares(newValue);
    } else {
      if (newValue < 1) {
        setNumShares(1);
      } else if (newValue > maxNumShares) {
        setNumShares(maxNumShares);
      }
    }
  };

  useEffect(() => {
    getBetDetailsByEventId();
    getEventDeailsById();
    getOrderBookDetailsByEventId();
    divRef.current.scrollIntoView();

    if (isLoggedIn) {
      getUserDetails();
      getUserBalance();
    }
    window.scrollTo(0, 0);
  }, [id, isLoggedIn]);

  useEffect(() => {
    if (outcomeSelect === 'Yes') {
      setSharePrice(yesSharePrice);
    } else if (outcomeSelect === 'No') {
      setSharePrice(noSharePrice);
    }
  }, [outcomeSelect, yesSharePrice, noSharePrice]);

  useEffect(() => {
    if (activeTab === 'sell' && outcomeSelect) {
      let userOrder = getUserOrder(outcomeSelect);
      console.log('finalmuser irder', userOrder);
      let maxNumShares = userOrder.reduce(
        (sum, order) => sum + order.availableShares,
        0,
      );
      setNumShares(maxNumShares);
      setMaxNumShares(maxNumShares);
    } else {
      setMaxNumShares(500);
    }
  }, [outcomeSelect]);

  useEffect(() => {
    const yesUserOrderExists = !!getUserOrder('yes');
    const noUserOrderExists = !!getUserOrder('no');

    if (!yesUserOrderExists && !noUserOrderExists) {
      setIsSellTabActive(false);
    } else {
      setIsSellTabActive(true);
    }

    if (activeTab === 'sell') {
      setIsActiveYesButton(yesUserOrderExists);
      setIsActiveNoButton(noUserOrderExists);

      if (yesUserOrderExists) {
        setOutcomeSelect('Yes');
      } else if (noUserOrderExists) {
        setOutcomeSelect('No');
      }
    } else {
      setIsActiveYesButton(true);
      setIsActiveNoButton(true);
      setOutcomeSelect(null);
    }
  }, [activeTab, userOrders]);

  const getUserOrder = type => {
    //return true
    //return userOrders.find(({shareType}) => shareType === type.toUpperCase())
    return userOrders.filter(
      ({ shareType, orderType, status }) =>
        shareType === type.toUpperCase() && orderType === 'BUY',
    );
  };

  const handleOrderClick = (outcome, price) => {
    if (outcome === 'Yes') {
      console.log('Setting Yes Share Price: ', price); // Debugging line
      setYesSharePrice(price);
    } else if (outcome === 'No') {
      console.log('Setting No Share Price: ', price); // Debugging line
      setNoSharePrice(price);
    }
    setOutcomeSelect(outcome); // Setting outcome to Yes or No
    setSharePrice(price); // Assuming you have a general sharePrice state to set as well

    // More Debugging lines to ensure states are set correctly
    console.log('Outcome After Set: ', outcomeSelect);
    console.log('Yes Share Price After Set: ', yesSharePrice);
    console.log('No Share Price After Set: ', noSharePrice);
  };

  return (
    <div className="container trade-event" ref={divRef}>
      <div className="row mt-3">
        <div className="col-12 col-md-5 col-lg-4">
          <div className="price-card p-4">
            {Object.keys(eventDetails).length > 0 && (
              <>
                <div className="d-flex align-items-center mt-2">
                  <NavLink to={`/portfolio/events/${id}`} className="nav-link">
                    {' '}
                    <span>
                      {' '}
                      You placed {eventDetails?.number_of_orders?.total} orders
                    </span>
                    <i class="bi bi-arrow-right arrow-link"></i>
                  </NavLink>
                </div>
                <hr />
              </>
            )}

            <Form onSubmit={handleOrder}>
              {/* Outcome for betting */}
              <Form.Group className="mb-3">
                <Form.Label className="fw-bold mb-0">Outcome</Form.Label>
                <div className="d-flex align-items-center mt-2">
                  <Button
                    variant={
                      outcomeSelect === 'Yes' ? 'success' : 'outline-success'
                    }
                    className="me-2 w-50"
                    onClick={() => setOutcomeSelect('Yes')}
                    disabled={!isActiveYesButton}
                  >
                    {outcomeSelect === 'Yes' && (
                      <i className="bi bi-check-circle-fill me-2"></i>
                    )}
                    Yes{/* - {yesSharePrice}¢*/}
                  </Button>
                  <Button
                    variant={
                      outcomeSelect === 'No' ? 'danger' : 'outline-danger'
                    }
                    className="w-50"
                    onClick={() => setOutcomeSelect('No')}
                    disabled={!isActiveNoButton}
                  >
                    {outcomeSelect === 'No' && (
                      <i className="bi bi-check-circle-fill me-2"></i>
                    )}
                    No{/* - {noSharePrice}¢*/}
                  </Button>
                </div>
              </Form.Group>

              {/* Number of shares */}
              <Form.Group className="mb-3 text-center">
                <Form.Label className="fw-bold mb-0 text-center">
                  Number of Shares (max {maxNumShares})
                </Form.Label>
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="d-flex align-items-center justify-content-center mt-2">
                  <button
                    type="button"
                    className="rounded-circle"
                    onClick={decrementShares}
                  >
                    -
                  </button>
                  <input
                    type="number"
                    value={numShares}
                    onChange={handleNumSharesChange}
                    min={1}
                    max={maxNumShares}
                    className="mx-2 text-center"
                    style={{ width: '40px' }}
                  />
                  <button
                    type="button"
                    className="rounded-circle"
                    onClick={incrementShares}
                  >
                    +
                  </button>
                </div>
              </Form.Group>

              {/* Share price */}

              <Form.Group className="mb-3">
                <div className="d-flex align-items-center mt-2">
                  <Form.Label className="fw-bold mb-0">Share Price</Form.Label>
                  <Slider
                    min={0.5}
                    max={9.5}
                    step={0.5}
                    value={sharePrice}
                    onChange={handleSharePriceChange}
                  />
                  <span className="ml-10">
                    ₹{Number(sharePrice).toFixed(2)}
                  </span>
                </div>
              </Form.Group>

              {/* Submit button */}
              <Button
                variant="primary"
                type="submit"
                className="w-100 mt-2"
                disabled={
                  !isLoggedIn ||
                  !outcomeSelect ||
                  numShares === '' ||
                  sharePrice === '' ||
                  dateFormate?.compareDates(eventDetails?.event_end_date) ===
                    'lesser' ||
                  eventDetails?.status !== 'open'
                }
              >
                {isLoggedIn
                  ? dateFormate?.compareDates(eventDetails?.event_end_date) ===
                      'lesser' || eventDetails?.status !== 'open'
                    ? 'Event Closed'
                    : 'Submit'
                  : 'Login to Predict'}
              </Button>
            </Form>

            <hr />

            {/* Market Timeline */}
            <div className="mt-2 mb-4">
              <h4 className="app-text-primary">Market Timeline</h4>
              <hr />
              <div className="row">
                <div className="col-2 col-lg-1">
                  {dateFormate?.compareDates(eventDetails?.event_start_date) ===
                  'lesser' ? (
                    <i className="bi bi-check-circle-fill app-text-primary fs-4"></i>
                  ) : (
                    <i className="bi bi-circle app-text-primary fs-4"></i>
                  )}
                </div>
                <div className="col-10 col-lg-11">
                  <h5 className="app-text-primary">Event Start</h5>
                  <p className="text-secondary">
                    {dateFormate?.formatDate(eventDetails?.event_start_date)}
                  </p>
                </div>
                <div className="col-2 col-lg-1">
                  {dateFormate?.compareDates(eventDetails?.event_end_date) ===
                  'lesser' ? (
                    <i className="bi bi-check-circle-fill app-text-primary fs-4"></i>
                  ) : (
                    <i className="bi bi-circle app-text-primary fs-4"></i>
                  )}{' '}
                </div>
                <div className="col-10 col-lg-11">
                  <h5 className="app-text-primary">Event Closure</h5>
                  <p className="text-secondary">
                    {dateFormate?.formatDate(eventDetails?.event_end_date)}
                  </p>
                </div>
                <div className="col-2 col-lg-1">
                  {dateFormate?.compareDates(
                    eventDetails?.event_settlement_date,
                  ) === 'lesser' ? (
                    <i className="bi bi-check-circle-fill app-text-primary fs-4"></i>
                  ) : (
                    <i className="bi bi-circle app-text-primary fs-4"></i>
                  )}{' '}
                </div>
                <div className="col-10 col-lg-11">
                  <h5 className="app-text-primary">Event Settlement </h5>
                  <p className="text-secondary">
                    {dateFormate?.formatDate(
                      eventDetails?.event_settlement_date,
                    )}
                  </p>
                </div>
                {/* setelement link */}
              </div>
            </div>
            <div className="d-flex"></div>
          </div>
        </div>
        <div className="col-12 col-md-7 col-lg-8 order-md-first">
          {/* event title */}
          <div className="row">
            <div className="col-12 text-center">
              <img
                src={eventDetails?.image?.s3Url}
                alt="icon"
                className="w-100"
              />
            </div>
            <div className="col-md-12 mt-2 mt-md-0">
              <div className="d-flex justify-content-between align-items-center">
                <span className="app-bg-grey p-2 rounded">
                  Event Ends :{' '}
                  {dateFormate?.formatTopDate(eventDetails?.event_end_date)}
                </span>
              </div>
              <h3 className="fw-bold fs-4 mt-3 mb-0">
                {eventDetails.event_name}
              </h3>
            </div>
          </div>
          <hr />
          {/* event description */}
          <div className="mt-2">
            <h4 className="app-text-primary">Market Resolution</h4>
            <hr />
            {/* <pre> {eventDetails?.event_description}</pre> */}
            <pre
              dangerouslySetInnerHTML={{
                __html: eventDetails?.event_description,
              }}
            />
          </div>

          {/* calculation */}
          <Orderlist details={orderData} onOrderClick={handleOrderClick} />
        </div>
      </div>
    </div>
  );
};

export default Orderbook;
