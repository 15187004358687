import './wallet.scss';

import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as TransactionService from '../../api/transaction';
import * as WalletService from '../../api/wallet';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { convertUsdToInr, convertInrToUsd } from '../../utils/currencyHandler';
import * as loginServe from '../../api/auth';
import { fetchUserBank } from '../../api/userDetails';
import { useNavigate } from 'react-router-dom';
import { usePagination } from '../../hooks/usePagination';
import { TransactionTable } from './table';
import { useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../../store/loader-slice';

const DEFAULT_PARAMS = {
  page: 1,
  perPage: 10,
};

const Wallet = () => {
  useDocumentTitle('My Wallet');

  const [walletBalance, setWalletBalance] = useState(0);
  const [mainBalance, setMainBalance] = useState(0);
  const [bonusBalance, setBonusBalance] = useState(0);
  const [winBalance, setWinBalance] = useState(0);

  const [transactions, setTransactions] = useState([]);
  const [activeTab, setActiveTab] = useState('deposits');
  const [isKycVerified, setIsKycVerified] = useState(false);
  const [isBankAccount, setIsBankAccount] = useState(false);
  const { setMeta, meta, query } = usePagination();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // pagination states

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await loginServe.getBalance();

        setIsKycVerified(user?.data?.is_kyc_verified || false);

        const bankAccount = await fetchUserBank();
        if (bankAccount.data.accountExists) {
          setIsBankAccount(bankAccount?.data?.accountExists || false);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();

    WalletService.getWalletBalance()
      .then(response => {
        setWalletBalance(
          response.data.mainBalance + response.data.bonusBalance,
        );
        setMainBalance(response.data.mainBalance);
        setBonusBalance(response.data.bonusBalance);
        setWinBalance(response.data.winBalance);
      })
      .catch(error => {
        console.error(error);
      });

    getAllTransaction({
      isDeposit: activeTab === 'deposits',
      page: query?.page ? query?.page : DEFAULT_PARAMS.page,
      perPage: query?.perPage ? query?.perPage : DEFAULT_PARAMS.perPage,
    });

    window.scrollTo(0, 0);
  }, []);
  // const depositTransactions = transactions.filter((tx) => tx.isDeposit);
  // const withdrawalTransactions = transactions.filter((tx) => !tx.isDeposit);

  const getAllTransaction = async ({ page, perPage, isDeposit }) => {
    dispatch(showLoader());
    // Fetch all transactions for the user
    try {
      const { data } = await TransactionService.getAllTxsByUserId({
        page,
        perPage,
        isDeposit,
      });
      setMeta(data?.pagination);

      const transactions = data.data.map(tx => ({
        isDeposit: tx.isDeposit,
        orderId: tx.orderId,
        formattedOrderId: 'FB-' + tx.orderId.slice(-4),
        paymentStatus: tx.paymentStatus,
        payAmount: tx.payAmount,
        payCurrency: tx.payCurrency,
        priceAmount: tx.priceAmount,
        priceCurrency: tx.priceCurrency,
        updatedAt: tx.updatedAt,
        actuallyPaid: tx.actuallyPaid,
        actuallyPaidInFiat: tx.actuallyPaidInFiat,
        bonusPaid: tx.bonusPaid,
        priceAmountLocal: tx.priceAmountLocal,
        priceCurrencyLocal: tx.priceCurrencyLocal,
        payment_gateway: tx.payment_gateway,
      }));

      setTransactions(transactions);
    } catch (error) {
      console.error(error);
    }

    dispatch(hideLoader());
  };

  // Navigation functions
  const navigateToDeposit = () => {
    navigate('/deposit');
  };

  const navigateToWithdraw = () => {
    if (!isKycVerified) {
      navigate('/kyc');
    } else if (!isBankAccount) {
      navigate('/connect-bank-account');
    } else {
      navigate('/fiat-withdraw');
    }
  };

  const handlePagination = (page, perPage) => {
    getAllTransaction({ isDeposit: activeTab === 'deposits', page, perPage });
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-header text-center">My Wallet</div>
            <div className="card-body d-flex flex-column align-items-center">
              <h6>
                Main balance:{' '}
                <span style={{ color: 'green', fontWeight: 'bold' }}>
                  ₹{convertUsdToInr(mainBalance)}
                </span>
              </h6>
              <h6>
                Winning balance:{' '}
                <span style={{ color: 'blue', fontWeight: 'bold' }}>
                  ₹{convertUsdToInr(winBalance)}
                </span>
              </h6>

              <h6>
                Promotional balance:{' '}
                <span style={{ color: 'blue', fontWeight: 'bold' }}>
                  ₹{convertUsdToInr(bonusBalance)}
                </span>
              </h6>

              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                <button
                  onClick={navigateToDeposit}
                  className="btn btn-primary me-md-2"
                >
                  Deposit
                </button>
                <button
                  onClick={navigateToWithdraw}
                  className="btn btn-secondary"
                >
                  Withdraw
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <h2 className="text-center my-4">Transaction History</h2> */}

      <ul className="nav nav-tabs mb-4 my-4">
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 'deposits' ? 'active' : ''}`}
            onClick={() => {
              setActiveTab('deposits');
              getAllTransaction({
                isDeposit: true,
                page: 1,
                perPage: 10,
              });
            }}
          >
            Deposits
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${
              activeTab === 'withdrawals' ? 'active' : ''
            }`}
            onClick={() => {
              setActiveTab('withdrawals');
              getAllTransaction({
                isDeposit: false,
                page: 1,
                perPage: 10,
              });
            }}
          >
            Withdrawals
          </button>
        </li>
      </ul>
      <TransactionTable
        activeTab={activeTab}
        data={transactions}
        meta={meta}
        handlePagination={handlePagination}
      />
    </div>
  );
};

export default Wallet;
