import axios from 'axios';
import Token from '../utils/tokenHandler';
import { exitToken } from '../store/token-slice';
import { userTimezone } from '../utils/timezone';

const API_BASE_URL = `${process.env.REACT_APP_BACKEND_URL}`;

const getApiClient = () => {
  const apiClient = axios.create({
    baseURL: API_BASE_URL,
  });

  apiClient.interceptors.request.use(
    config => {
      let token = Token();
      const tokenData = JSON.parse(token.getToken());
      token = tokenData?.token || '';
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      config.headers['Timezone'] = userTimezone;

      return config;
    },
    error => {
      Promise.reject(error);
    },
  );
  apiClient.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response && error.response.status === 401) {
        console.log('Token Expired. Redirecting to login page...');
        Token().removeToken();
        //dispatch(exitToken());
        // Redirect the user to the login page
        if (window.location.pathname !== '/login') {
          window.location.href = '/login';
        }
        //window.location.href = '/login';
      }
      return Promise.reject(error);
    },
  );

  return apiClient;
};

const apiClient = getApiClient();

export default apiClient;
