import { useState } from 'react';

import { convertUsdToInr } from '../../utils/currencyHandler';

import './portfolio.scss';

export const BetsTable = ({ bets, status, onStatusChange }) => {
  const [activeTab, setActiveTab] = useState(status);

  const handleTabClick = tab => {
    setActiveTab(tab);
    onStatusChange(tab);
  };

  const generateInvestedTooltip = bet => {
    return (
      <div className="tooltip-content">
        <div>Winning: ₹{convertUsdToInr(bet.from_win_amount).toFixed(0)}</div>
        <div>Main: ₹{convertUsdToInr(bet.main_amount).toFixed(0)}</div>
        <div>Promotional: ₹{convertUsdToInr(bet.bonus_amount).toFixed(0)}</div>
      </div>
    );
  };
  const generateWinningTooltip = bet => {
    return (
      <div className="tooltip-content">
        <div>
          Winning: ₹{convertUsdToInr(bet.win_win_amount ?? 0).toFixed(0)}
        </div>
        <div>Main: ₹{convertUsdToInr(bet.win_main_amount ?? 0).toFixed(0)}</div>
        <div>
          Promotional: ₹{convertUsdToInr(bet.win_bonus_amount ?? 0).toFixed(0)}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="tabs">
        <button
          onClick={() => handleTabClick('open')}
          className={activeTab === 'open' ? 'active-tab' : ''}
        >
          Open
        </button>
        <button
          onClick={() => handleTabClick('settled')}
          className={activeTab === 'settled' ? 'active-tab' : ''}
        >
          Settled
        </button>
      </div>
      {bets.length > 0 ? (
        <table className="table table-hover table-responsive-md">
          <thead className="">
            <tr>
              <th className="fw-bold">Event</th>
              <th className="fw-bold">Invested</th>
              <th className="fw-bold">Prediction</th>
              <th className="fw-bold">Result</th>
              <th className="fw-bold">Multiplier</th>
              <th className="fw-bold">
                {status === 'Active' ? 'Probable Win' : 'You won'}
              </th>
              {status !== 'Active' && <th className="fw-bold">Credited</th>}
            </tr>
          </thead>
          <tbody>
            {bets.map((x, i) => {
              const multiplier =
                x?.bet_result === 'Yes'
                  ? x.event_id.multiplier_yes
                  : x.event_id.multiplier_no;
              const winAmount =
                x.status === 'open'
                  ? x.bet_amount * multiplier
                  : x.win_amount.toFixed(2);
              const creditedAmount =
                status !== 'Active' ? 0.9 * Number(winAmount) : '';

              return (
                <tr key={i} className={i % 2 === 0 ? 'bg-light' : ''}>
                  <td>
                    <p>{x?.event_id?.event_name}</p>
                  </td>
                  <td className="hoverable-cell">
                    <p>₹{convertUsdToInr(x?.bet_amount).toFixed(0)}</p>
                    <div className="custom-tooltip">
                      {generateInvestedTooltip(x)}
                    </div>
                  </td>{' '}
                  <td>
                    <p>{x?.bet_result}</p>
                  </td>
                  <td>
                    <p>{x?.event_id?.result ? x?.event_id?.result : '___'}</p>
                  </td>
                  <td className="">
                    <p>
                      {' '}
                      {multiplier} x {x.bet_result}
                    </p>
                  </td>
                  <td className="">
                    <p>₹{convertUsdToInr(Number(winAmount)).toFixed(0)}</p>
                  </td>
                  {status !== 'Active' && (
                    <td className="hoverable-cell">
                      <p>₹{convertUsdToInr(creditedAmount).toFixed(0)}</p>
                      <div className="custom-tooltip">
                        {generateWinningTooltip(x)}
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className="text-center mt-4">
          <h4>No predictions found.</h4>
        </div>
      )}
    </div>
  );
};
