import { useNavigate } from 'react-router-dom';
import './trade-card.scss';
import { IoFootballOutline, IoAirplaneOutline } from 'react-icons/io5';
import { IoBasketball } from 'react-icons/io5';
import {
  MdSportsTennis,
  MdSportsCricket,
  MdBadminton,
  MdBasketballHoopOutline,
  MdSmartphone,
  MdEvent,
} from 'react-icons/md';
import { TiChartLine } from 'react-icons/ti';
import { AiFillYoutube, AiOutlineBullseye } from 'react-icons/ai';
import { GiTrophy, GiFilmProjector, GiVote, GiBullseye } from 'react-icons/gi';
import { RiTrophyLine, RiInstagramLine } from 'react-icons/ri';
import { WiDayStormShowers, WiDayHail, WiDaySunny } from 'react-icons/wi';
import Orderlist from '../../pages/Event/components/orderlist';

const TradeCard = ({ details }) => {
  const navigate = useNavigate();
  // navigating to event page
  const showEvent = id => {
    details?.type === 'order'
      ? navigate(`/orderbook/${id}`)
      : navigate(`/event/${id}`);
  };

  const imageStyle = {
    backgroundImage: `url(${details?.image?.s3Url})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '100%',
    width: '100%',
  };

  details.betsdata = !details.betsdata ? [] : details.betsdata;

  let yesAmount = details.betsdata.find(ele => ele._id === 'Yes');
  yesAmount = !yesAmount ? 0 : yesAmount.bet_amount;
  let noAmount = details.betsdata.find(ele => ele._id === 'No');
  noAmount = !noAmount ? 0 : noAmount.bet_amount;

  const total = yesAmount + noAmount;

  const yesPercentage =
    total === 0 ? 50 : Math.round((yesAmount / total) * 100);
  const noPercentage = total === 0 ? 50 : Math.round((noAmount / total) * 100);

  const array = Array.from({ length: 19 }, (_, i) => 0.5 + i * 0.5);

  const getCategoryIcon = () => {
    switch (details.icon_name) {
      case 'cricket':
        return <MdSportsCricket size={50} color="#FF0000" />;
      case 'football':
        return <IoFootballOutline size={50} color="#00FF00" />;
      case 'tennis':
        return <MdSportsTennis size={50} color="#00FF00" />;
      case 'badminton':
        return <MdSportsTennis size={50} color="#FFFF00" />;
      case 'basketball':
        return <IoBasketball size={50} color="#FFFF00" />;
      case 'stocks':
        return <TiChartLine size={50} color="#FF0000" />;
      case 'sports':
        return <MdSmartphone size={50} color="#FF0000" />;
      case 'crypto':
        return <MdSmartphone size={50} color="#FFFF00" />;
      case 'award':
        return <GiTrophy size={50} color="#FFFF00" />;
      case 'movies':
        return <MdEvent size={50} color="#0000FF" />;
      case 'film':
        return <GiFilmProjector size={50} color="#0000FF" />;
      case 'event':
        return <MdEvent size={50} color="#FFFF00" />;
      case 'vote':
        return <GiVote size={50} color="#FF0000" />;
      case 'trophy':
        return <GiTrophy size={50} color="#00FF00" />;
      case 'smartphone':
        return <MdSmartphone size={50} color="#0000FF" />;
      case 'flight':
        return <IoAirplaneOutline size={50} color="#FFFF00" />;
      case 'weather':
        return <WiDayStormShowers size={50} color="#FF0000" />;
      case 'weather2':
        return <WiDayHail size={50} color="#00FF00" />;
      case 'weather3':
        return <WiDaySunny size={50} color="#0000FF" />;
      case 'instagram':
        return <RiInstagramLine size={50} color="#FF0000" />;
      case 'youtube':
        return <AiFillYoutube size={50} color="#00FF00" />;
      case 'airplane':
        return <IoAirplaneOutline size={50} color="#0000FF" />;
      default:
        return <GiBullseye size={50} color="#808080" />;
    }
  };

  const getMinSharedPrice = type => {
    const currentType = details?.orderData.find(el => el?.shareType === type);

    if (currentType?.sharePrice) {
      return currentType?.sharePrice;
    } else {
      return 0;
    }
  };

  const getBlockMurk = (type, key, typeCard) => {
    const textType = typeCard === 'yes' ? 'Yes' : 'No';
    return (
      <div className="progress-bar-container">
        <span className="progress-label">
          {type === 'percentage' ? `${key}%` : `${textType} ${key}`}{' '}
        </span>
        <div
          className={`progress-bar-fill-${typeCard}`}
          style={{ width: type === 'percentage' ? `${key}%` : '70px' }}
        ></div>
      </div>
    );
  };

  return (
    <div
      className="trade-card border my-1 rounded"
      onClick={() => showEvent(details?._id)}
    >
      <div className="trade-card-icon">{getCategoryIcon()}</div>
      <div className="trade-card-content">
        <div className="event-details">
          <h6 className="event-name fw-bold">
            {details?.event_name}
            {details?.event_type && <span>({details?.event_type})</span>}
          </h6>
        </div>
        <div className="progress-bars">
          {details?.type === 'pool' ? (
            <>
              {getBlockMurk('percentage', yesPercentage, 'yes')}
              {getBlockMurk('percentage', noPercentage, 'no')}
            </>
          ) : (
            <>
              {getBlockMurk('price', getMinSharedPrice('YES'), 'yes')}
              {getBlockMurk('price', getMinSharedPrice('NO'), 'no')}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TradeCard;
