import { Pagination } from '../../components/pagination/pagination';

export const TransactionTable = ({
  activeTab,
  data,
  meta,
  handlePagination,
}) => {
  const getPagination = () => (
    <div className={`pagination-wrapper`} style={{ height: 50 }}>
      {Object.keys(meta).length > 0 && (
        <Pagination meta={meta} handlePagination={handlePagination} />
      )}
    </div>
  );

  return (
    <>
      {activeTab === 'deposits' && (
        <div className="row justify-content-center mt-5 mb-4">
          <div className="col-12">
            <div className="card">
              <div className="card-header">Transaction History</div>
              <div className="card-body">
                <table className="table table-hover table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">Order ID</th>

                      <th scope="col">Status</th>
                      <th scope="col">You Promised</th>

                      <th scope="col">You Paid</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map(transaction => (
                      <tr key={transaction.orderId}>
                        <td>
                          <small>
                            {new Date(transaction.updatedAt).toLocaleString()}
                          </small>
                        </td>
                        <td>
                          <small>{transaction.formattedOrderId}</small>
                        </td>
                        <td>
                          {transaction.paymentStatus === 'waiting' && (
                            <i className="bi bi-hourglass-split me-2"></i>
                          )}
                          {transaction.paymentStatus === 'confirming' && (
                            <i className="bi bi-clock me-2"></i>
                          )}
                          {transaction.paymentStatus === 'confirmed' && (
                            <i className="bi bi-clock me-2"></i>
                          )}

                          {transaction.paymentStatus === 'finished' && (
                            <i className="bi bi-check-circle-fill text-success me-2"></i>
                          )}
                          {transaction.paymentStatus === 'partially_paid' && (
                            <i className="bi bi-exclamation-triangle-fill text-warning me-2"></i>
                          )}
                          {transaction.paymentStatus === 'failed' && (
                            <i className="bi bi-x-circle-fill text-danger me-2"></i>
                          )}
                          {transaction.paymentStatus === 'expired' && (
                            <i className="bi bi-x-circle-fill text-danger me-2"></i>
                          )}
                          <small>{transaction.paymentStatus}</small>
                        </td>

                        <td style={{ fontWeight: '400', color: '#999' }}>
                          <small>
                            {transaction.payment_gateway === 'nowpayments'
                              ? `${transaction.payAmount} ${transaction.payCurrency} (${transaction.priceAmount} ${transaction.priceCurrency})`
                              : transaction.payment_gateway === 'onmeta'
                                ? `${transaction.priceCurrencyLocal}  ${transaction.priceAmountLocal}  `
                                : ''}
                          </small>
                        </td>

                        <td style={{ fontWeight: '500', color: '#000' }}>
                          {transaction.paymentStatus === 'finished' ||
                          transaction.paymentStatus === 'partially_paid' ||
                          transaction.paymentStatus === 'processed' ? (
                            <span>
                              {transaction.payment_gateway === 'nowpayments'
                                ? `${transaction.actuallyPaid}
                              ${transaction.payCurrency} (
                              ${transaction.actuallyPaidInFiat}
                              ${transaction.priceCurrency})`
                                : transaction.payment_gateway === 'onmeta'
                                  ? ` ${transaction.priceCurrencyLocal} ${transaction.actuallyPaidInFiat}`
                                  : ''}

                              {transaction.bonusPaid &&
                                transaction.bonusPaid > 0 &&
                                ` (+ ${transaction.bonusPaid} Bonus)`}
                            </span>
                          ) : (
                            <>
                              <i className="bi bi-hourglass-split me-2"></i>
                              {'Pending'}
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {getPagination()}
              </div>
            </div>
          </div>
        </div>
      )}
      {activeTab === 'withdrawals' && (
        <div className="row justify-content-center mt-5 mb-4">
          <div className="col-12">
            <div className="card">
              <div className="card-header">Transaction History</div>
              <div className="card-body">
                <table className="table table-hover table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">Order ID</th>

                      <th scope="col">Status</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Trasaction Hash</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map(transaction => (
                      <tr key={transaction.orderId}>
                        <td>
                          {new Date(transaction.updatedAt).toLocaleString()}
                        </td>
                        <td>
                          <small>{transaction.formattedOrderId}</small>
                        </td>
                        <td>
                          {transaction.paymentStatus === 'waiting' ? (
                            <i className="bi bi-hourglass-split me-2"></i>
                          ) : (
                            <i className="bi bi-check-circle-fill text-success me-2"></i>
                          )}
                          {transaction.paymentStatus}
                        </td>

                        <td>
                          {transaction.payment_gateway === 'nowpayments'
                            ? `${transaction.priceAmount} ${transaction.priceCurrency}`
                            : transaction.payment_gateway === 'onmeta'
                              ? `${transaction.priceAmountLocal} ${transaction.priceCurrencyLocal}`
                              : ''}
                        </td>
                        <td>
                          {transaction.payment_gateway === 'nowpayments' ? (
                            transaction.paymentStatus === 'waiting' ? (
                              <div>
                                <i className="bi bi-hourglass-split me-2"></i>
                              </div>
                            ) : (
                              <div>
                                <i className="bi bi-check-circle-fill text-success me-2"></i>
                                {transaction.withdrawalDetail}
                              </div>
                            )
                          ) : (
                            transaction.payment_gateway === 'onmeta' && (
                              <div>NA</div>
                            )
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {getPagination()}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
