import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as ContactService from '../../api/contact';
import * as loginServe from '../../api/auth';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const ContactUsPage = () => {
  useDocumentTitle('Contact Us');

  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const isLoggedIn = useSelector(state => state.token.isTokenExist);

  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const user = await loginServe.getBalance();
        setEmail(user.data.email);
      } catch (error) {
        console.log(error);
      }
    };
    if (isLoggedIn) {
      getUserDetails();
    }
  }, [isLoggedIn]);

  const handleSubmit = async event => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await ContactService.createEntry({ subject, body, email, isLoggedIn });
      setIsSubmitting(false);
      setIsSubmitted(true);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container">
      <h1>Contact Us</h1>
      {isSubmitted ? (
        <p>Thank you for your message! We will get back soon.</p>
      ) : (
        <form onSubmit={handleSubmit}>
          {isLoggedIn ? (
            <div className="form-group mt-4">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                disabled
                required
              />
            </div>
          ) : (
            <div className="form-group mt-4">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={event => setEmail(event.target.value)}
                required
              />
            </div>
          )}
          <div className="form-group mt-4">
            <label htmlFor="subject">Subject:</label>
            <input
              type="text"
              className="form-control"
              id="subject"
              value={subject}
              onChange={event => setSubject(event.target.value)}
              required
            />
          </div>
          <div className="form-group mt-4">
            <label htmlFor="body">Message:</label>
            <textarea
              className="form-control"
              id="body"
              rows="5"
              value={body}
              onChange={event => setBody(event.target.value)}
              required
            ></textarea>
          </div>

          <button
            type="submit"
            className="btn btn-primary"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      )}
    </div>
  );
};

export default ContactUsPage;
