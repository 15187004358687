import { createSlice } from '@reduxjs/toolkit';
import Token from '../utils/tokenHandler';

const token = Token();

const initialState = {
  isTokenExist: token.isTokenExist(),
};

export const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setToken: state => {
      state.isTokenExist = true;
    },
    exitToken: state => {
      state.isTokenExist = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setToken, exitToken } = tokenSlice.actions;

export default tokenSlice.reducer;
