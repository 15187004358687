import React from 'react';

const TNC = () => {
  return (
    <div className="container mb-4">
      <h1>Terms and Conditions</h1>
      <p className="mt-4">
        Welcome to FutureBash. If you continue to browse and use this website,
        you agree to comply with and be bound by the following terms and
        conditions of use, which together with our privacy policy govern
        FutureBash's relationship with you in relation to this website. If you
        disagree with any part of these terms and conditions, please do not use
        our website.
      </p>
      <p>
        The term 'FutureBash' or 'us' or 'we' refers to the owner of the
        website.
      </p>
      <p>The use of this website is subject to the following terms of use:</p>
      <p>
        The content of the pages of this website is for your general information
        and use only. It is subject to change without notice.
      </p>
      <p>
        This website uses cookies to monitor browsing preferences. If you do
        allow cookies to be used, the following personal information may be
        stored by us for use by third parties: IP address, Geolocation, browser
        type, etc.
      </p>
      <p>
        Neither we nor any third parties provide any warranty or guarantee
        regarding the accuracy, timeliness, performance, completeness, or
        suitability of the information and materials found or offered on this
        website for any particular purpose. You acknowledge that such
        information and materials may contain inaccuracies or errors and we
        expressly exclude liability for any such inaccuracies or errors to the
        fullest extent permitted by law.
      </p>
      <p>
        Your use of any information or materials on this website is entirely at
        your own risk, for which we shall not be liable. It shall be your own
        responsibility to ensure that any products, services, or information
        available through this website meet your specific requirements.
      </p>
      <p>
        You are not a resident, national, or agent of Algeria, Bangladesh,
        Bolivia, Belarus, Burundi, Burma (Myanmar), Cote D'Ivoire (Ivory Coast),
        Crimea and Sevastopol, Cuba, Democratic Republic of Congo, Ecuador,
        Iran, Iraq, Liberia, Libya, Mali, Morocco, Nepal, North Korea, Somalia,
        Sudan, Syria, Venezuela, Yemen, Zimbabwe or any other country to which
        Canada, Panama, the United States, the United Kingdom or the European
        Union embargoes goods or imposes similar sanctions (collectively,
        “Restricted Territories”);
      </p>
      <p>
        You are not a member of any sanctions list or equivalent maintained by
        the Panamanian government, the United States government, the United
        Kingdom government, the European Union, or the United Nations
        (collectively, “Sanctions Lists Persons”) and you do not intend to
        transact with any Restricted Person or Sanctions List Person;
      </p>
      <p>
        It is your responsibility to abide by the local laws of your resident
        country and use the platform only if it is legally allowed for you to do
        so.{' '}
      </p>
      <p>Welcome Bonus Withdrawal Clause:</p>
      <p>
        Users who receive a welcome bonus on the [FutureBash] platform are
        kindly advised to withdraw the bonus amount within 60 days from the date
        of receiving the bonus. Failure to withdraw the welcome bonus within the
        specified period will result in the forfeiture of the bonus amount.
      </p>
      <p>
        [FutureBash] reserves the right to modify or terminate the welcome bonus
        policy at any time. We strive to provide the best user experience, and
        timely withdrawal of the welcome bonus ensures that users can make the
        most of their event prediction journey.
      </p>
      <p>
        Manipulation and Multiple Accounts: FutureBash strictly prohibits any
        form of manipulation or unfair practices on the platform. Users found
        engaging in activities such as using multiple accounts to gain an unfair
        advantage will face immediate consequences, including account
        suspension, banning, and forfeiture of any associated winnings.
      </p>
      <p>
        We maintain a fair and transparent environment for all participants,
        ensuring that everyone has an equal opportunity to predict and win based
        on their own skills and judgment. Any winnings obtained through
        manipulation or violation of our terms will not be authorized or
        honored.
      </p>
      <p>
        FutureBash reserves the right to investigate and take appropriate action
        against any user suspected of violating these terms. By using our
        platform, you agree to abide by these rules and understand that any
        violation may result in the termination of your account and loss of
        associated privileges.
      </p>
      <p>
        This website contains material that is owned by or licensed to us. This
        material includes but is not limited to, the design, layout, look,
        appearance, and graphics. Reproduction is prohibited other than in
        accordance with the copyright notice, which forms part of these terms
        and conditions.
      </p>
      <p>Cryptocurrency Transactions </p>
      <p>
        [FutureBash] operates as an event prediction platform, and we have
        partnered with a third-party service provider to facilitate seamless
        local payment collection and disbursement methods in India. To ensure
        that users always deal in fiat currency and shield them from market
        fluctuations, we have implemented the following process:{' '}
      </p>
      <p>
        1. Deposit and Fiat Representation: - Users can deposit funds into their
        [FutureBash] wallet to participate in event predictions on the platform.
        - Behind the scenes, our third-party payment partner, acting on behalf
        of the user, converts the deposited amount into cryptocurrency at the
        current exchange rate. - The corresponding fiat value is displayed in
        the user's [FutureBash] wallet for complete transparency and user
        convenience. - Regardless of cryptocurrency market fluctuations, the
        value in the user's wallet remains constant in fiat terms, ensuring that
        users are unaffected by crypto price volatility.{' '}
      </p>
      <p>
        2. Withdrawal and Tax Deduction: - When a user initiates a withdrawal
        request, the corresponding cryptocurrency in the wallet is sold by our
        third-party payment partner at the current exchange rate. - As mandated
        by the Indian government, our payment partner will deduct a 1% Tax
        Deducted at Source (TDS) on all cryptocurrency sales. - The deducted TDS
        amount will be remitted to the government on behalf of the user. - The
        remaining fiat amount, after deducting TDS, will be seamlessly
        transferred to the user's designated bank account, preserving the fiat
        value initially deposited.{' '}
      </p>
      <p>
        3. User's Tax Obligations: - [FutureBash] and its third-party payment
        partner shall not be held liable for any tax liabilities arising from
        cryptocurrency transactions on our platform. - Users are responsible for
        complying with all applicable tax laws and regulations related to
        cryptocurrency transactions in their respective jurisdiction. - It is
        advisable for users to seek professional tax advice regarding their
        cryptocurrency transactions and obligations.
      </p>{' '}
      <p>
        By utilizing the [FutureBash] platform and engaging in cryptocurrency
        transactions, users acknowledge and agree to the terms outlined in this
        section. Rest assured that the value in the user's wallet remains
        constant in fiat terms, offering a secure and consistent experience
        regardless of cryptocurrency market fluctuations.
      </p>
      <p>
        We are committed to providing a seamless and reliable platform for event
        predictions, and our partnership with our third-party payment provider
        ensures that your funds are always represented in fiat terms.
      </p>
      <p>
        [FutureBash] reserves the right to modify these terms as needed and will
        notify users of any changes. Please note that this clause is subject to
        the terms of our partnership with the third-party payment provider.
      </p>
      <p>
        All trademarks reproduced in this website, which are not the property
        of, or licensed to the operator, are acknowledged on the website.
      </p>
      <p>
        Unauthorized use of this website may give rise to a claim for damages
        and/or be a criminal offense.
      </p>
      <p>
        From time to time, this website may also include links to other
        websites. These links are provided for your convenience to provide
        further information. They do not signify that we endorse the website(s).
        We have no responsibility for the content of the linked website(s).
      </p>
      <p>
        Your use of this website and any dispute arising out of such use of the
        website is subject to the laws of Estonia.
      </p>
    </div>
  );
};

export default TNC;
