export const validateEmail = email => {
  const result = /\S+@\S+\.\S+/.test(email);
  if (!result) return ['email', 'Invalid Email Address'];
  else return [];
};

export const validateMobileNumber = mobile => {
  const result = /^(?:[0-9] ?){9,14}[0-9]$/.test(mobile);
  if (!result)
    return ['mobile', 'Invalid Mobile Number [ Length should be 10 )'];
  else return [];
};

export const validatePassword = password => {
  const result = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(password);
  if (!result)
    return [
      'password',
      'Password must contain at least one number, one uppercase and one lowercase letter, and be at least 6 characters long.',
    ];
  else return [];
};
