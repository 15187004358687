import { useEffect, useState } from 'react';
import * as ReferralService from '../../api/refer';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { convertUsdToInr, convertInrToUsd } from '../../utils/currencyHandler';

const MyReferralPage = () => {
  useDocumentTitle('My Referrals');

  const [referralCode, setReferralCode] = useState('');
  const [referralLink, setReferralLink] = useState('');
  const [totalReferrals, setTotalReferrals] = useState(0);
  const [referralEarnings, setReferralEarnings] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [referralTransactions, setReferralTransactions] = useState([]);

  useEffect(() => {
    setLoading(true);

    ReferralService.getReferralDetails()
      .then(response => {
        setReferralCode(response.data.referralDetails.referralCode);
        setReferralLink(response.data.referralLink);
        setTotalReferrals(response.data.totalReferrals);
      })
      .catch(error => {
        console.log('Error getting referral details:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);

    ReferralService.getReferralEarnings()
      .then(response => {
        setReferralTransactions(response.data.referralEarnings);
        setReferralEarnings(response.data.totalEarnings);
      })
      .catch(error => {
        console.log('Error getting referral earnings:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleButtonClick = () => {
    setIsButtonClicked(true);
    navigator.clipboard.writeText(referralLink);
  };

  return (
    <div className="container">
      <div className="alert alert-success mt-4" role="alert">
        Refer your friends and earn money! You will get 1% of the profits made
        by your referrals. LIFETIME.
      </div>
      <h1 className="my-4">My Referral </h1>

      {/* <div className="card mb-4">
  <div className="card-body">
    <div className="d-flex justify-content-between align-items-center">
      <h5 className="mb-0">My Referral Code:</h5>
     
    </div>
    <hr className="my-2" />
    <p className="mb-0">{referralCode}</p>
  </div>
</div> */}

      <div className="card mb-4">
        <div className="card-body">
          <h5 className="mb-3">My Referral Link:</h5>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <p className="mb-0">{referralLink}</p>
            <button
              className={`btn btn-primary ${
                isButtonClicked ? 'btn-clicked' : ''
              }`}
              onClick={handleButtonClick}
            >
              {isButtonClicked ? 'Copied!' : 'Copy'}
            </button>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <h5>Referral Statistics:</h5>
          {loading && <p>Loading referral stats...</p>}
          {!loading && (
            <div className="d-flex justify-content-between bg-light mb-3 p-3 rounded">
              <div>
                <h6>Total Referrals</h6>
                <p className="fw-bold">{totalReferrals}</p>
              </div>
              <div>
                <h6>Referral Earnings</h6>
                <p className="fw-bold">
                  ₹{convertUsdToInr(referralEarnings).toFixed(0)}
                </p>
              </div>
            </div>
          )}
          <p className="text-muted mb-0">
            You can transfer your earnings to main balance once it reaches 2400
            INR.
          </p>
        </div>
      </div>

      <div className="card mt-4 mb-4">
        <div className="card-body">
          <h5>All Earnings:</h5>
          {loading && <p>Loading referral transactions...</p>}
          {!loading && referralTransactions.length === 0 && (
            <p>No referral transactions to show.</p>
          )}
          {!loading && referralTransactions.length > 0 && (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col"> Date</th>
                  <th scope="col">Earning</th>
                </tr>
              </thead>
              <tbody>
                {referralTransactions.map(transaction => (
                  <tr key={transaction._id}>
                    <td>{new Date(transaction.createdAt).toLocaleString()}</td>
                    <td>
                      ₹{convertUsdToInr(transaction.amountEarned).toFixed(0)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyReferralPage;
