import apiClient from './apiClient';

export const getEvents = ({ id, page, perPage }) => {
  return apiClient.get(`/events`, {
    params: {
      category: id,
      page,
      perPage,
    },
  });
};

export const getEventById = id => {
  return apiClient.get(`/events/${id}`);
};

export const createBet = (id, data) => {
  return apiClient.post(`/events/${id}`, data);
};

export const getMyBets = (status, page = 1, perPage) => {
  return apiClient.get(`/bets/user`, {
    params: {
      status,
      page,
      perPage,
    },
  });
};

export const getOrderStatForEvent = id => {
  return apiClient.get(`/events/${id}/orderStat`, {});
};
export const getBetsForAnEvent = id => {
  return apiClient.get(`/bets/event/${id}`, {});
};
export const getBetAmountForAnUser = id => {
  return apiClient.get(`/bets/betamount`, {});
};

export const createOrder = (id, data) => {
  return apiClient.post(`/events/order/${id}`, data);
};

export const sellOrder = (id, data) => {
  return apiClient.post(`/events/sellOrder/${id}`, data);
};

export const getOrderBookDetailsForAnEvent = id => {
  return apiClient.get(`/events/orderDetails/${id}`);
};

export const getAllEventsForUser = (status, page = 1, perPage) => {
  return apiClient.get(`/events/allEventsForUser`, {
    params: {
      status,
      page,
      perPage,
    },
  });
};
