import React from 'react';
import QRCode from 'qrcode.react';
import './TopBanner.scss';
import {
  FaBasketballBall,
  FaFilm,
  FaNewspaper,
  FaFlagUsa,
  FaChartLine,
  FaBitcoin,
} from 'react-icons/fa';
import { SiPhonepe, SiGooglepay, SiPaytm } from 'react-icons/si';
import upi from '../../assets/images/upi.png';
import { FaGift } from 'react-icons/fa';

export default function CustomBanner() {
  const title = 'FutureBash is now live on Android.';
  const description =
    'Predict. Participate. Prosper. Your Future starts at Futurebash.';
  const description2 =
    'Joining Bonus of ₹40 | 20% bonus on your first deposit.';
  const apkLink =
    'https://future-bash.s3.us-east-2.amazonaws.com/apk/futurebash_v1_8.apk';

  const categories = [
    { name: 'Sports', icon: <FaBasketballBall size={30} /> },
    { name: 'Media', icon: <FaFilm size={30} /> },
    { name: 'News', icon: <FaNewspaper size={30} /> },
    { name: 'Politics', icon: <FaFlagUsa size={30} /> },
    { name: 'Stocks', icon: <FaChartLine size={30} /> },
    { name: 'Crypto', icon: <FaBitcoin size={30} /> }, // add the new category here
  ];

  const paymentMethods = [
    {
      name: 'Upi',
      icon: (
        <img
          src={upi}
          alt="Upi"
          style={{ width: '60px', height: '25px', marginTop: '15px' }}
        />
      ),
    },
    { name: 'PhonePe', icon: <SiPhonepe size={45} color="green" /> },
    { name: 'Google Pay', icon: <SiGooglepay size={60} color="green" /> },
    { name: 'Paytm', icon: <SiPaytm size={60} color="green" /> },
  ];

  return (
    <div className="hero-banner">
      <h1 className="title">{title}</h1>
      <p className="description">{description}</p>
      <a href={apkLink} className="download-btn" download>
        Download APK
      </a>
      <p className="description1">Or, Scan the QR code. </p>
      <div className="qr-code">
        <QRCode value={apkLink} size={128} />
      </div>
      <br />
      <p className="description1" style={{ color: '#000000', padding: '10px' }}>
        <FaGift size={20} />
        {'  '} {description2}
      </p>

      <h4 className="my-2">Deposits with UPI and Direct Withdrawals to Bank</h4>
      <div className="row mb-4">
        {paymentMethods.map((payment, index) => (
          <div className="col text-center" key={index}>
            {payment.icon}
          </div>
        ))}
      </div>

      <div className="row mt-4">
        {categories.map((category, index) => (
          <div className="col text-center" key={index}>
            {category.icon}
            <p className="category-name">{category.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
